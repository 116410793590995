import React from 'react';
import {RiLoader5Line} from 'react-icons/ri';
import '../Sass/GeneralStyle/loader.scss';
import {useSelector} from "react-redux";
import {useCheck} from "../Hooks/onDefaultValueCheck/onDefaultValueCheck";

const Loader=(props)=>{
    const [check]=useCheck();
    const sideBarOpen = useSelector(state=>check(state,'ui.sideBarOpen',true));
    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const mode = useSelector(state=>check(state,'login.userInfo.darkMode',defaultDark));

    return (
        <div className='loaderContainer' data-theme={mode?"dark":"light"}>
        <div className={sideBarOpen?'icn-spinner text-center centerSideOpen':'icn-spinner text-center center'} >
            <RiLoader5Line size={props.size?props.size:100} className='purple'/>
        </div>
        </div>
    );
};

export default Loader;
