import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actionCreators from './index';
import {backendURL, backendConfig} from "../utility";

export const patientDeletedMsg =(data)=>{
    return {
        type:actionTypes.PATIENT_DELETED,
        patientDeleted:data
    };
};

export const deletePatient =(id)=>{
    return dispatch=> {
        if(id){
            dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
            axios.delete(`${backendURL}/patient/${id}`,backendConfig)
                .then( resp  => {
                    dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                    dispatch(actionCreators.resetErrorField());
                    dispatch(actionCreators.madeAchange(false));
                    dispatch(actionCreators.userMadeAChange(false));
                    dispatch(actionCreators.madeAChangeHospital(false));
                    dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                    dispatch(patientDeletedMsg({
                        patientDeletedMsg:resp.data.response,
                        isPatientDeleted:true
                    }));
                }).catch(error=>{
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));

                if(error && error.response){
                    dispatch(actionCreators.handleBackendError(error.response,false,"patientDelete"));
                }else{
                    if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
                }
            });
        }else{
            dispatch(patientDeletedMsg({
                patientDeletedMsg:'Patient does not exist',
                isPatientDeleted:false
            }));
        }
    };
};