import * as actionTypes from "./actionTypes";


export const changeOnMonitoring =(name,value)=>{
    return {
        type:actionTypes.CHANGE_MONITORING,
        name: name,
        value:value
    };
};
export const resetOnChangeMonitoring =()=>{
    return {
        type:actionTypes.RESET_CHANGE_MONITORING
    };
};


export const showMonitoringsList =(name,list)=>{
    return {
        type:actionTypes.SHOW_MONI_LIST,
        name,
        list
    };
};

export const noToAllFallMonitoring =(index)=>{
    return {
        type:actionTypes.NO_TO_ALL_MONITORING,
        index:index,
        value:{
            referralFallsService:"NO",
            strengthBalance:"NO",
            visionAssessment:"NO",
            homeOccupationTherapy:"NO",
            medicationReview:"NO",
        }
    };
};

export const addPlannedMonitoring =(value,type,path)=>{
    return {
        type:actionTypes[type],
        value,
        path
    };
};

