import React from "react";
import {useSelector} from "react-redux";
import {useCheck} from "../../../../Hooks/onDefaultValueCheck/onDefaultValueCheck";
import {useTranslation} from "react-i18next";
import {FiDownload} from 'react-icons/fi';

const UpdateLink=(props)=>{
    const [check] = useCheck();
    const serverMsgModal=useSelector(state=>check(state,'modal.modalServerMsg',false));
    const { t } = useTranslation();

    const checkIfOnlyUpdate=()=>{
        if(serverMsgModal && serverMsgModal.msg){
            for(let message of serverMsgModal.msg){
                if(message.type === "UPDATE") return <span>| <a href={message.url} className="white" target="_blank"  rel='noreferrer noopener'>
                    <FiDownload className='me-2  contextIcoSmall'/>
                    {t('app.update')}
                </a> </span> ;
            }
        }
        return false;
    };

    return <div className="d-inline ">
        {checkIfOnlyUpdate()}
    </div>;
};

export default UpdateLink ;