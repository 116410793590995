import axios from "axios";
import {frontendURL} from "../Store/utility";


let parsedFile;
const parsePropertiesFile = (properties) => {
    return properties.split('\n')
        .filter(line => '' !== line.trim())
        /*.map(line => line.split('='))
        .map(tokens => ({
            [tokens[0]]: tokens[1]
        }))*/
        .map(line => ({
            [line.substring(0, line.indexOf('='))]: line.substring(line.indexOf('=')+1)
        }))
        .reduce((properties, property) => ({
            ...properties,
            ...property
        }), {});
};



const getFile=(path)=>{
    axios.get(`${frontendURL}${path}`)
        .then(resp=>parsedFile=parsePropertiesFile(resp.data))
        .catch(err=>err);
};

const getTrans = (key) => {
    if(parsedFile)
        return parsedFile[key]?parsedFile[key]:"Key does not exist !";
    else
        return "";
};

export {getFile,parsedFile,getTrans};