import React, {useEffect, useState, Suspense} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as actionCreators from "../../../Store/Actions";
import {FaCaretRight} from "react-icons/fa";
import {useCheck} from "../../../Hooks/onDefaultValueCheck/onDefaultValueCheck";
import {moto, brand} from "../../../Store/utility";
import process from 'process';
import MiniLoader from "../../../Loader/MiniLoader";


const BasicLogin = () => {
    const [check] = useCheck();
    const { t, } = useTranslation();
    const [username,setUsername]=useState("");
    const [psw,setPsw]=useState("");
    let history = useNavigate();
    const dispatch = useDispatch();
    const [logo,setLogo]=useState();
    const timedOut=useSelector(state=>check(state,'login.timedOut',false));
    const logged=useSelector(state=>check(state,'login.logged',false));
    const error=useSelector(state=>check(state,'error.generalSaveMsg',false));
    const logginSpin=useSelector(state=>check(state,'login.loading',false));

    useEffect(()=>{
        let isMounted = true;
        if(process && process.env.REACT_APP_BRAND!=='') {
            import (`../../Corporate/assets/images/logo-${process.env.REACT_APP_BRAND}-login.png`).then(resp => {
                if(isMounted)setLogo(resp.default);
                return resp;
            }).catch(() => {
                import("../../Corporate/assets/images/logo--login.png").then(resp => {
                    if(isMounted)setLogo(resp.default);
                    return resp;
                });
            });
        } else {
            import("../../Corporate/assets/images/logo--login.png").then(resp => {
                if(isMounted)setLogo(resp.default);
                return resp;
            });
        }
        return () => { isMounted = false; };
    },[process,process.env.REACT_APP_BRAND]);


    const checkLoginInputField=()=>{
        if(username.length===0 || psw.length===0) {
            dispatch(actionCreators.setGeneralSaveMsg(t('app.emptyCred'),true));
            return true;
        }else {
            return false;
        }
    };

    const onUsernameChange=(e)=>{
        setUsername(e.target.value);
    };
    const onPasswordChange=(e)=>{
        setPsw(e.target.value);
    };
    const onEnterPress=(e)=>{
        if(e.key==='Enter') {
            if(!checkLoginInputField()){
                sendLogInfo();
            }
        }
    };

    const onClickIcon=()=>{
        if(!checkLoginInputField()){
            sendLogInfo();
        }
    };

    useEffect(()=>{
        window.scrollTo(0, 0);
        if(logged &&  !timedOut){
            history('/app/dashboard');
        }
    },[history, logged,timedOut]);

    const  sendLogInfo=  ()=>{
        dispatch(actionCreators.userChange(username, psw));
    };

//heightWithFooter
    return (
        <main className='content' >
            <section className="homeBasic gradient-before generic-banner p-0" style={{boxSizing: 'initial'}}>
                <div className="gradient-overlay" ></div>
                <div className="content-wrapper container text-start">
                    <div className="content max-width-md white p-3 pt-5">
                        <Trans i18nKey="loginPage.home.body.intro" >

                            <h1 className="title white breakWord">
                                Welcome to <span>{{brand}}</span> <small>{{moto}} Software</small>
                            </h1>
                            <p>
                                <strong>The {{brand}} {{moto}} Software</strong> will be your best partner to manage
                                your prevention service and reduce the care gap, ensuring that fragility fractures sufferers
                                receive appropriate assessment and monitoring to reduce future fracture risk.
                            </p>
                            <p>
                                Our software has been developed in close partnership with deep domain experts to deliver a
                                state-of-the-art solution for the <strong>data management of Fracture Liaison
                                Services</strong>.
                            </p>
                        </Trans>
                        <div id="connection" className="connection-modal p-3 row">
                            <div className="col-md-5 col-lg-5 pl-md-0">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <img src={logo} className="logoAppBarLogin"
                                         alt="Logo"/>
                                </Suspense>
                            </div>
                            <div className="col-md-7 col-lg-7">
                                <form>
                                    <input  onChange={onUsernameChange}
                                            id="username" name="username" type="text" className='p-3' placeholder={t('loginPage.home.userNamePlaceholder')}/>
                                    <input  onChange={onPasswordChange}
                                            onKeyPress={onEnterPress}
                                            id="password" name="password" type="password" className='p-3' placeholder={t('loginPage.home.passwordPlaceholder')}/>
                                    <label className="btn-submit pl-0"  style={{maxWidth:'100%'}}>
								<span onClick={onClickIcon}  className="btn-primary w-100 p-3">
                                    {
                                        logginSpin
                                            ?<MiniLoader size={20} color='whiteSpinner'/>
                                            :<>
                                                <FaCaretRight style={{color: 'white !important',marginBottom:'2px'}}/>
                                                {t('loginPage.home.connectBtn')}
                                            </>
                                    }

								</span>

                                    </label>
                                    {/*<p className="btn-small newCursor" title={t('loginPage.home.forgotPasswordTitle')}>
                                        {t('loginPage.home.forgotPassword')}
                                    </p>*/}
                                </form>
                                {
                                    error && error.error
                                        ?<p className='text-danger text-center'>
                                            {
                                                error.translation
                                                    ?t(error.msg)
                                                    :error.msg + " " + (error.details?error.details:'')
                                            }
                                        </p>
                                        :null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    );
};

export default BasicLogin;