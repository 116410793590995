import get from 'lodash/get';

//todo maybe change that fct into a static one

export const useCheck = () => {
    const check = (state,name,defaultVal,isStateInCorrect,updatedState) => {
        if(isStateInCorrect){
            if(get(updatedState, name, defaultVal)===null){
                return defaultVal;
            }else{
                return get(updatedState, name, defaultVal);
            }
        }else{
            if(get(state, name, defaultVal)===null){
                return defaultVal;
            }else{
                return get(state, name, defaultVal);
            }

        }

    };
    return [check];
};
