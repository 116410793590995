import * as actionCreators from './index';
import axios from 'axios';
import {backendConfig,baseURL} from "../utility";
//systemMetrics

export const getCpuUsage =()=>{
    return dispatch=> {
        axios.get(`${baseURL}/actuator/metrics/system.cpu.usage`,backendConfig )
            .then( resp  => {
                if(resp.data && resp.data.measurements && resp.data.measurements[0]){
                    dispatch(actionCreators.onGeneralChange(
                        {target:{name:"systemMetrics.cpuUsage",value:resp.data.measurements[0].value}}
                        , 'HOSPITAL_DETAILS_ONCHANGE'));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const getMemoryUsed =()=>{
    return dispatch=> {
        axios.get(`${baseURL}/actuator/metrics/jvm.memory.used`,backendConfig )
            .then( resp  => {
                if(resp.data && resp.data.measurements && resp.data.measurements[0]){
                    dispatch(actionCreators.onGeneralChange(
                        {target:{name:"systemMetrics.memoryUsed",value:resp.data.measurements[0].value}}
                        , 'HOSPITAL_DETAILS_ONCHANGE'));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const getMemoryMax =()=>{
    return dispatch=> {
        axios.get(`${baseURL}/actuator/metrics/jvm.memory.max`,backendConfig )
            .then( resp  => {
                if(resp.data && resp.data.measurements && resp.data.measurements[0]){
                    dispatch(actionCreators.onGeneralChange(
                        {target:{name:"systemMetrics.memoryMax",value:resp.data.measurements[0].value}}
                        , 'HOSPITAL_DETAILS_ONCHANGE'));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const getActive =()=>{
    return dispatch=> {
        axios.get(`${baseURL}/actuator/metrics/tomcat.sessions.active.current`,backendConfig )
            .then( resp  => {
                if(resp.data && resp.data.measurements && resp.data.measurements[0]){
                    dispatch(actionCreators.onGeneralChange(
                        {target:{name:"systemMetrics.active",value:resp.data.measurements[0].value}}
                        , 'HOSPITAL_DETAILS_ONCHANGE'));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const getUptime =()=>{
    return dispatch=> {
        axios.get(`${baseURL}/actuator/metrics/process.uptime`,backendConfig)
            .then( resp  => {
                if(resp.data && resp.data.measurements && resp.data.measurements[0]){
                    dispatch(actionCreators.onGeneralChange(
                        {target:{name:"systemMetrics.uptime",value:resp.data.measurements[0].value}}
                        , 'HOSPITAL_DETAILS_ONCHANGE'));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


export const getDiskFree =()=>{
    return dispatch=> {
        axios.get(`${baseURL}/actuator/metrics/disk.free`,backendConfig )
            .then( resp  => {
                if(resp.data && resp.data.measurements && resp.data.measurements[0]){
                    dispatch(actionCreators.onGeneralChange(
                        {target:{name:"systemMetrics.diskFree",value:resp.data.measurements[0].value}}
                        , 'HOSPITAL_DETAILS_ONCHANGE'));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const getDiskTotal =()=>{
    return dispatch=> {
        axios.get(`${baseURL}/actuator/metrics/disk.total`,backendConfig )
            .then( resp  => {
                if(resp.data && resp.data.measurements && resp.data.measurements[0]){
                    dispatch(actionCreators.onGeneralChange(
                        {target:{name:"systemMetrics.diskTotal",value:resp.data.measurements[0].value}}
                        , 'HOSPITAL_DETAILS_ONCHANGE'));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};



