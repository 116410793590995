import * as actionTypes from "./actionTypes";
import * as actionCreators from "./index";
import axios from "axios";
import { setGetPatient} from "./index";
import {backendURL, backendConfig} from "../utility";


export const createNewCA =(prevFract,date)=>{
    return {
        type: actionTypes.CREATE_CA,
        prevFract,
        date
    };
};

export const updateCA =(caIndex)=>{
    return {
        type: actionTypes.UPDATE_CA,
        caIndex
    };
};

export const deleteClinicalAssess =(selected)=>{
    return {
        type: actionTypes.DELETE_CA,
        selected
    };
};

export const resetClinical =()=>{
    return {
        type: actionTypes.RESET_CLINICAL,
        resetedClinical:{
            clinicalContent:{
                clinicalAssessment:[],
                boneDensityResults:[],
                currentData:null,
                treatmentRecommendations:[],
                hipFracture:null
            }
        }
    };
};

export const deleteClinicalAssessBack =(id)=>{
    return dispatch=> {
        dispatch(actionCreators.loading(true));
        dispatch(actionCreators.updateCA(0)); //change maybe that
        axios.delete(`${backendURL}/patient/clinicalassessment/${id}`,backendConfig)
            .then( resp  => {
                dispatch(setGetPatient(resp.data.payload));
                dispatch(actionCreators.updateCA(resp.data.payload.clinicalContent.clinicalAssessment.length-1>0
                    ?resp.data.payload.clinicalContent.clinicalAssessment.length-1
                    :0)); //change maybe that
                dispatch(actionCreators.loading(false));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loading(false));
        });
    };
};

export const sendPdf =(file,setModalState,setProgressBar,hid,folder)=>{
    let config = {
        ...backendConfig,
        onUploadProgress: function(progressEvent) {
            let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            if(percentCompleted<100)setProgressBar(percentCompleted);

        }
    };
    return dispatch=> {
        let formData = new FormData();
        formData.append("file", file);
        axios.post(`${backendURL}/resources/${hid}/${folder}`,formData, config)
            .then( resp  => {
                setProgressBar(100);
                setModalState('dxaFile', {dxaFile:resp.data.payload})  ;
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const getPdf =(dxaFileName,hid,folder)=>{
    return dispatch=> {
        axios.get(`${backendURL}/resources/${hid}/${folder}/${dxaFileName}`,backendConfig)
            .then( resp  => {
                console.log(resp);
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};