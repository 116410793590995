import setWith from "lodash/setWith";
import clone from "lodash/clone";
import process from 'process';
import { CookieJar } from 'tough-cookie';

export const updateObject = (oldObject, updatedValues) => {
    return {
        ...oldObject,
        ...updatedValues
    };
};

//maybe use _.cloneDeep
//the bug comes from the clone method
export const updateDeepObject = (oldObject, propertyPath, updatedValues) => {
    return {...setWith(clone(oldObject), propertyPath, updatedValues, clone)};
};

export const frontendURL = window.env ? window.env.REACT_APP_FRONTEND_URL : process.env.REACT_APP_FRONTEND_URL;
export const backendURL = window.env
    ? (window.env.REACT_APP_BACKEND_URL +"/api/v1")
    : (process.env.REACT_APP_BACKEND_URL+"/api/v1");
export const baseURL = window.env ? window.env.REACT_APP_BACKEND_URL : process.env.REACT_APP_BACKEND_URL;

const cookieJar = new CookieJar();
export const backendConfig = {
    headers: {
        'Content-Type' : 'application/json',
    },
    withCredentials: true,
    jar:cookieJar

};

export const brand =  process.env.REACT_APP_BRAND
    ?process.env.REACT_APP_BRAND
    :"BoneMonitor";

export const moto =  process.env.REACT_APP_MOTO
    ?process.env.REACT_APP_MOTO
    :"FLS Manager";