import * as actionTypes from './actionTypes';
import * as actionCreators from './index';
import get from 'lodash/get';
import {errorLocation, patientDeletedMsg} from "./index";


export const setErrorField =(name,error,targetName)=>{
    return {
        type: actionTypes.ERROR_FIELD,
        errorInput:{
            name:name,
            value: {
                error:error,
                targetField:targetName
            }
        }
    };
};

export const resetErrorField =()=>{
    return {
        type: actionTypes.RESET_ERROR_FIELD
    };
};


export const notFound =(bool)=>{
    return {
        type: actionTypes.NOT_FOUND,
        notFound:bool
    };
};

export const setGeneralSaveMsg=(msg="",error=false,detail="",translation=false)=>{
    return {
        type: actionTypes.GENERAL_SAVE_MSG,
        generalSaveMsg: {
            msg,
            detail,
            error,
            translation
        }
    };
};

export const serverMsg =(msg,open)=>{
    return {
        type: actionTypes.SERVER_MSG,
        modalServerMsg:{
            open:open,
            msg:msg
        }
    };
};

export const handleBackendError = (error,patientId,loc) => {
    return dispatch=> {
        if(error) {
            if (error.status === 401) {
                // sessionStorage.removeItem('user');
                //sessionStorage.removeItem('credentials');
                dispatch(actionCreators.timedOut());
                dispatch(actionCreators.logout('Timed out'));
                return dispatch(actionCreators.redirectToHomePage('/',''));
            }
            else if (error.status === 500) {
                console.log("#####################################################");
                console.log("############# UNHANDLED BACK-END ERROR ############# ");
                console.log("#####################################################");
                return {type: actionTypes.ERROR, error: error};
            } else {
                console.log(get(error, 'data.error.details', ""));
                dispatch(actionCreators.setGeneralSaveMsg(get(error, 'data.error.message', "") ,
                    true,
                    get(error, 'data.error.details', "") && get(error, 'data.error.details', "")!==""
                        ?get(error, 'data.error.details', "").length>90
                            ?get(error, 'data.error.details', "").substring(0, 90)+'...'
                            :get(error, 'data.error.details', "")
                        :""));
                if(get(error, 'data.error.message', "") ==="Person not found"){
                    dispatch(actionCreators.notFound(true));
                }
                if(loc==="patientDelete"){
                    if( get(error, 'data.error.message', false)){
                        dispatch(patientDeletedMsg({
                            patientDeletedMsg:get(error, 'data.error.message', ""),
                            isPatientDeleted:false
                        }));
                    }else{
                        dispatch(patientDeletedMsg({
                            patientDeletedMsg:"Patient could not be deleted",
                            isPatientDeleted:false
                        }));
                    }
                }
                if(error.data && error.data.error && error.data.error.field) {
                    dispatch(errorLocation(get(error, 'data.error.field', false)));
                    if(get(error, 'data.error.field', "").includes('patientId')){
                        dispatch(actionCreators.setErrorField('errorField.patientId',true,patientId));
                    }
                    const scrollToElem=()=>{
                        let elmnt= document.getElementsByName((get(error, 'data.error.field', false)))[0];
                        if(elmnt) {
                            elmnt.scrollIntoView();
                            const y = elmnt.getBoundingClientRect().top + window.pageYOffset + -90;
                            window.scrollTo({top: y, behavior: 'smooth'});
                        }
                    };
                    scrollToElem();
                }
                return {type: actionTypes.ERROR, error: error};
            }
        } else {
            console.log("#####################################################");
            console.log("############# UNHANDLED BACK-END ERROR ############# ");
            console.log("#####################################################");
            return {type: actionTypes.ERROR, error: error};
        }
    };

};