import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actionCreators from './index';
import {backendURL, backendConfig} from "../utility";

export const updatePermissions =(permissions,name)=>{
    return {
        type:actionTypes.UPDATE_PERMISSIONS,
        permissions:permissions,
        name:name
    };
};

export const setOrderedPermissions =(data)=>{
    return {
        type:actionTypes.SET_PERMISSIONS,
        permissions:data
    };
};

export const setPermissions =(data)=>{
    return (dispatch,getState)=> {

        const findRole=(permRole)=>{
            let sortedRoles=getState().contextHospital.roles;
            sortedRoles.sort((a,b)=>{
                return a.y-b.y;
            });
            for(let i=0;i<sortedRoles.length;i++){
                if(permRole.slug===sortedRoles[i].slug){
                    return sortedRoles[i].y;
                }
            }
        };
        const orderPermissions=(permissions)=>{
            let newArr=permissions;
            let sortedPerm=[];
            if(newArr.length>=0 && getState().contextHospital.roles.length>=0){
                newArr=newArr.map(perm=>{
                    return {
                        ...perm,
                        permission_grants:perm.permission_grants.map(permGrant=>{
                            return {
                                ...permGrant,
                                role_grants:permGrant.role_grants.map((permRole,t)=>{
                                    return {
                                        ...permRole,
                                        y:findRole(permRole)
                                    };
                                })
                            };
                        })

                    };
                });
                sortedPerm=newArr.map(perm=>{
                    return {
                        ...perm,
                        permission_grants:perm.permission_grants.map(permGrant=>{
                            return {
                                ...permGrant,
                                role_grants:permGrant.role_grants.sort((a,b)=>{
                                    return a.y-b.y;
                                })
                            };
                        })

                    };
                });
            }
            return sortedPerm;
        };
        return dispatch(setOrderedPermissions(orderPermissions(data)));
    };
};



export const permissionsLoading =(data)=>{
    return {
        type:actionTypes.PERMISSIONS_LOADING,
        permissionsLoading: data,
    };
};

export const sendPermissions =(permissions,hid)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/hospital/${hid}/settings/permissions`,
            permissions,
            backendConfig).then(resp=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                    dispatch(actionCreators.madeAChangeHospital(false));
                    dispatch(actionCreators.userMadeAChange(false));
                 dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                 dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.permSaved",false));
            })
            .catch(error=>{
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                if(error && error.response){
                    dispatch(actionCreators.handleBackendError(error.response));
                }else{
                    if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
                }
            });
    };
};


export const getPermissions =(hid)=>{
    return dispatch=> {
        dispatch(permissionsLoading(true));
        axios.get(`${backendURL}/hospital/${hid}/settings/permissions`,backendConfig)
            .then( resp  => {
                dispatch(permissionsLoading(false));
                dispatch(setPermissions(resp.data.payload));
            }).catch(error=>{
            if(error && error.response){
                dispatch(permissionsLoading(false));
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};
