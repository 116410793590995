import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actionCreators from './index';
import {backendURL, backendConfig} from "../utility";

export const getTask = (userId,orderby='status',dir='asc',days='',hide="true",taskType='',page='1') => {

    let data = {
        orderby: orderby,
        direction: dir,
        days: days,
        hidedone: hide,
        tasktype: taskType,
        page: page.toString()
    };
    return dispatch=> {
        axios.post(`${backendURL}/dashboard/tasks/${userId}`,data, backendConfig)
            .then( resp  => {
                if(resp.data.payload){
                    dispatch(setTask(resp.data.payload));
                    dispatch(setPatientCount(resp.data.payload));
                    dispatch(setTasksCount(resp.data.payload));
                }else{
                   // console.log('error');
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const setTask =(data)=>{
    return {
        type: actionTypes.SET_TASK,
        tasks: data.tasks,
    };
};

export const setTasksCount =(data)=>{
    return {
        type: actionTypes.SET_TASKS_COUNT,
        tasksCount:data.tasksCount
    };
};


export const setPatientCount =(data)=>{
    return {
        type: actionTypes.SET_PATIENT_COUNT,
        patientCount:data.patientCount
    };
};
export const getTaskByPatient = (patientId,orderby='status',dir='asc',days='',hide="true",taskType='',page='1') => {

    let data = {
        orderby: orderby,
        direction: dir,
        days: days,
        hidedone: hide,
        tasktype: taskType,
        page: page.toString()
    };
    return dispatch=> {
        axios.post(`${backendURL}/tasks/patient/${patientId}`, data, backendConfig )
            .then( resp  => {
                if(resp.data.payload){
                    dispatch(setTaskByPatient(resp.data.payload));
                }else{
                   // console.log('error');
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const setTaskByPatient =(data)=>{
    return {
        type: actionTypes.SET_TASK_BY_PATIENT,
        tasks: data.tasks.rows,
        patientTaskPagiInfo:{
            count:data.tasks.count,
            pageSize:data.tasks.pageSize,
            pageNumber:data.tasks.pageNumber
        }
    };
};
export const orderPatients =(data)=>{
    return {
        type: actionTypes.ORDER_BY_PATIENT,
        orderBy: data.orderBy,
        direction: data.direction
    };
};

export const patientGridLoading =(data)=>{
    return {
        type: actionTypes.PATIENT_GRID_LOADING,
        patientGridLoading: data
    };
};

export const getOrderedPatient =(searchterm, orderBy = 'lastName', direction = 'asc',page='1')=>{

    let data = {
        orderby: orderBy,
        direction: direction,
        searchterm: searchterm,
        page: page.toString()
    };
    return dispatch=> {
        dispatch(patientGridLoading(true));
        axios.post(`${backendURL}/dashboard/patients`, data, backendConfig )
            .then( resp  => {
                dispatch(patientGridLoading(false));
                dispatch(setPatientGrid(resp.data.payload));
                dispatch(patientGridLoading(null));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(patientGridLoading(false));
            dispatch(patientGridLoading(null));
        });
    };
};

export const setSearchPatient =(val)=>{

    return {
        type: actionTypes.SEARCH_PATIENT_INPUT,
        searchPatient:val
    };
};

export const setPatientGrid =(data)=>{
    return {
        type: actionTypes.PATIENT_GRID,
        patients:data
        /*patientSearchResults:data.patients,
        patientSearchResultsCount:data.count*/
    };
};

export const filterTasks =(days,hide,taskType)=>{
    return {
        type: actionTypes.FILTER_TASKS,
        days,
        hide,
        taskType
    };
};

export const filterTasksPatient =(days,hide,taskType)=>{
    return {
        type: actionTypes.FILTER_TASKS_PATIENTS,
        days,
        hide,
        taskType
    };
};

export const orderTasks =(orderByFieldName, direction)=>{
    return {
        type: actionTypes.ORDER_TASKS,
        orderBy:orderByFieldName,
        direction:direction
    };
};

export const orderTasksPatient =(orderByFieldName, direction)=>{
    return {
        type: actionTypes.ORDER_TASKS_PATIENT,
        orderBy:orderByFieldName,
        direction:direction
    };
};

//CSV DAHSBOARD
//TASK

export const loadingDashboardTaskCsv=(loading,ready)=>{
    return {
        type: actionTypes.LOADING_DASHBOARD_TASK_CSV,
        loadingDashboardTaskCsv:{
            loading,
            ready
        }
    };
};
export const pathDashboardTaskCSV=(pathDashboardTaskCSV)=>{
    return {
        type: actionTypes.PATH_DASHBOARD_TASK_CSV,
        pathDashboardTaskCSV:{
            ...pathDashboardTaskCSV
        }
    };
};
export const prepareDashboardTaskCSV =(userId,hide,orderby,direction,days,timezone,taskType)=>{
    let data = {
        orderby: orderby,
        direction: direction,
        days: days,
        hidedone: hide,
        tasktype: taskType,
        timezone: timezone
    };
    return dispatch=>{
        dispatch(actionCreators.loadingDashboardTaskCsv(true,false));
        axios.post(`${backendURL}/dashboard/tasks/${userId}/csv/start`, data, backendConfig)
            .then(resp=>{
                //dispatch(actionCreators.loadingSearchCsv(false,true));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingDashboardTaskCsv(false,false));
        });
    };
};

export const getDashboardTaskCsvStatus =()=>{
    return dispatch=>{
        dispatch(actionCreators.loadingDashboardTaskCsv(true,false));
        axios.get(`${backendURL}/dashboard/tasks/csv/status`,backendConfig)
            .then(resp=>{
                if(resp.data.payload.processing){
                    dispatch(actionCreators.loadingDashboardTaskCsv(true,false));
                }else{
                    if(resp.data.payload.fileSize>=0 && resp.data.payload.exportFilePath){
                        dispatch(actionCreators.pathDashboardTaskCSV({
                            exportFilePath:resp.data.payload.exportFilePath,
                            fileSize:resp.data.payload.fileSize
                        }));
                    }
                    dispatch(actionCreators.loadingDashboardTaskCsv(false,true));

                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingDashboardTaskCsv(false,false));

        });
    };
};


//PATIENT
export const pathDashboardPatientCSV=(pathDashboardPatientCSV)=>{
    return {
        type: actionTypes.PATH_DASHBOARD_PATIENT_CSV,
        pathDashboardPatientCSV:{
            ...pathDashboardPatientCSV
        }
    };
};

export const loadingDashboardPatientCsv=(loading,ready)=>{
    return {
        type: actionTypes.LOADING_DASHBOARD_PATIENT_CSV,
        loadingDashboardPatientCsv:{
            loading,
            ready
        }
    };
};
export const prepareDashboardPatientCSV =(searchTerm,orderby,direction)=>{
    let data = {
        orderby: orderby,
        direction: direction,
        searchterm: searchTerm
    };
    return dispatch=>{
        dispatch(actionCreators.loadingDashboardPatientCsv(true,false));
        axios.post(`${backendURL}/dashboard/patients/csv/start`, data, backendConfig)
            .then(resp=>{
                //dispatch(actionCreators.loadingSearchCsv(false,true));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingDashboardPatientCsv(false,false));
        });
    };
};

export const getDashboardPatientCsvStatus =()=>{
    return dispatch=>{
        dispatch(actionCreators.loadingDashboardPatientCsv(true,false));
        axios.get(`${backendURL}/dashboard/patients/csv/status`,backendConfig)
            .then(resp=>{
                if(resp.data.payload.processing){
                    dispatch(actionCreators.loadingDashboardPatientCsv(true,false));
                }else{
                    dispatch(actionCreators.loadingDashboardPatientCsv(false,true));
                    if(resp.data.payload.fileSize>=0 && resp.data.payload.exportFilePath){
                        dispatch(actionCreators.pathDashboardPatientCSV({
                            exportFilePath:resp.data.payload.exportFilePath,
                            fileSize:resp.data.payload.fileSize
                        }));
                    }
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingDashboardPatientCsv(false,false));

        });
    };
};



//PDF
//TASK

export const pathDashboardTaskPDF=(pathDashboardTaskPDF)=>{
    return {
        type: actionTypes.PATH_DASHBOARD_TASK_PDF,
        pathDashboardTaskPDF:{
            ...pathDashboardTaskPDF
        }
    };
};

export const loadingDashboardTaskPdf=(loading,ready)=>{
    return {
        type: actionTypes.LOADING_DASHBOARD_TASK_PDF,
        loadingDashboardTaskPdf:{
            loading,
            ready
        }
    };
};
export const prepareDashboardTaskPDF =(userId,hide,orderby,direction,days,timezone,taskType)=>{
    let data = {
        orderby: orderby,
        direction: direction,
        days: days,
        hidedone: hide,
        tasktype: taskType,
        timezone: timezone
    };
    return dispatch=>{
        dispatch(actionCreators.loadingDashboardTaskPdf(true,false));
        axios.post(`${backendURL}/dashboard/tasks/${userId}/pdf/start`, data, backendConfig)
            .then(resp=>{
                //dispatch(actionCreators.loadingSearchCsv(false,true));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingDashboardTaskPdf(false,false));
        });
    };
};

export const getDashboardTaskPdfStatus =()=>{
    return dispatch=>{
        dispatch(actionCreators.loadingDashboardTaskPdf(true,false));
        axios.get(`${backendURL}/dashboard/tasks/pdf/status`,backendConfig)
            .then(resp=>{
                if(resp.data.payload.processing){
                    dispatch(actionCreators.loadingDashboardTaskPdf(true,false));
                }else{
                    if(resp.data.payload.fileSize>=0 && resp.data.payload.exportFilePath){
                        dispatch(actionCreators.pathDashboardTaskPDF({
                            exportFilePath:resp.data.payload.exportFilePath,
                            fileSize:resp.data.payload.fileSize
                        }));
                    }
                    dispatch(actionCreators.loadingDashboardTaskPdf(false,true));

                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingDashboardTaskPdf(false,false));
        });
    };
};



//patient

export const loadingDashboardPatientPdf=(loading,ready)=>{
    return {
        type: actionTypes.LOADING_DASHBOARD_PATIENT_PDF,
        loadingDashboardPatientPdf:{
            loading,
            ready
        }
    };
};
export const pathDashboardPatientPDF=(pathDashboardPatientPDF)=>{
    return {
        type: actionTypes.PATH_DASHBOARD_PATIENT_PDF,
        pathDashboardPatientPDF:{
            ...pathDashboardPatientPDF
        }
    };
};
export const prepareDashboardPatientPDF =(searchTerm,orderby,direction,timezone)=>{
    let data = {
        orderby: orderby,
        direction: direction,
        searchterm: searchTerm,
        timezone: timezone
    };
    return dispatch=>{
        dispatch(actionCreators.loadingDashboardPatientPdf(true,false));
        axios.post(`${backendURL}/dashboard/patients/pdf/start`, data, backendConfig)
            .then(resp=>{
                //dispatch(actionCreators.loadingSearchCsv(false,true));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingDashboardPatientPdf(false,false));
        });
    };
};

export const getDashboardPatientPdfStatus =()=>{
    return dispatch=>{
        dispatch(actionCreators.loadingDashboardPatientPdf(true,false));
        axios.get(`${backendURL}/dashboard/patients/pdf/status`,backendConfig)
            .then(resp=>{
                if(resp.data.payload.processing){
                    dispatch(actionCreators.loadingDashboardPatientPdf(true,false));
                }else{
                    if(resp.data.payload.fileSize>=0 && resp.data.payload.exportFilePath){
                        dispatch(actionCreators.pathDashboardPatientPDF({
                            exportFilePath:resp.data.payload.exportFilePath,
                            fileSize:resp.data.payload.fileSize
                        }));
                    }
                    dispatch(actionCreators.loadingDashboardPatientPdf(false,true));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingDashboardPatientPdf(false,false));
        });
    };
};


export const downloadPath =(path)=>{
    return dispatch=> {
        window.location.href = path;
    };
};
