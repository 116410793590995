import * as actionCreators from './index';
import axios from 'axios';
import {backendURL, backendConfig} from "../utility";


export const getTranslation =(id,name,typeAct,lang)=>{
    return dispatch=> {
        if(id){
            axios.get(`${backendURL}/translated/${id}`,backendConfig )
                .then( resp  => {
                    let obj;
                    if(resp.data && resp.data.payload){
                        if(lang){
                            let filtered=resp.data.payload.translations.filter(elem=>elem.locale===lang);
                            obj={
                                target:{
                                    name,
                                    value:filtered && filtered[0] && filtered[0].val ?filtered[0].val:''
                                }
                            };
                        }else{
                            obj={
                                target:{
                                    name,
                                    value:resp.data.payload.translations
                                }
                            };
                        }
                        dispatch(actionCreators.onGeneralChange(obj, typeAct));

                    }
                }).catch(error=>{
                if(error && error.response){
                    dispatch(actionCreators.setGeneralSaveMsg(error.response.data.error.message ,true,error.response.data.error.details));
                    dispatch(actionCreators.handleBackendError(error.response));
                }else{
                    if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
                }
            });
        }
    };
};

export const deleteTranslation =(id)=>{
    return dispatch=> {
        if(id){
            axios.delete(`${backendURL}/translation/${id}`,backendConfig )
                .then( resp  => {
                    if(resp.data && resp.data.payload){
                        //
                    }
                }).catch(error=>{
                if(error && error.response){
                    dispatch(actionCreators.setGeneralSaveMsg(error.response.data.error.message ,true,error.response.data.error.details));
                    dispatch(actionCreators.handleBackendError(error.response));
                }else{
                    if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
                }
            });
        }
    };
};
