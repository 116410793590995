import React, {lazy, Suspense} from 'react';
import { useTranslation } from 'react-i18next';
import Loader from "../../Loader/Loader";
import BasicLogin from './BasicLogin/BasicLogin';
import {moto, brand} from "../../Store/utility";
import process from 'process';
import AppFooter from "../App/CustomComponent/AppFooter/AppFooter";
import {useSelector} from "react-redux";
import {useCheck} from "../../Hooks/onDefaultValueCheck/onDefaultValueCheck";
const CookieConsent = lazy(() => import('react-cookie-consent' /* webpackChunkName: "CookieConsent" */));
const Helmet = lazy(() => import('react-helmet' /* webpackChunkName: "helmet" */));



function BasicCorporatePage() {
    const { t} = useTranslation();
    const [check]=useCheck();
    const blindMode = useSelector(state=>check(state,'login.userInfo.blindMode',"NORMAL"));
   // const demo = useSelector(state=>check(state,'ui.demo',false));
    //const dispatch = useDispatch();

    /*useEffect(()=>{
        dispatch(actionCreators.getDemo());
    },[]);*/


    return (
            <div className='fullScreen fontMainSize' data-blind={blindMode}>
                <Suspense fallback={<Loader/>} >
                <Helmet>
                    <title>{brand}</title>
                    <meta name="description" content={t('loginPage.home.pageTitle', {brand: brand,moto:moto})} />
                </Helmet>
                </Suspense>
                <BasicLogin/>
                <Suspense fallback={<Loader/>} >
                    {window.env.REACT_APP_DEMO === "true" ?<CookieConsent
                        location="bottom"
                        buttonText={t('loginPage.cookie.button')}
                        cookieName="BMCookie"
                        style={{
                            zIndex: '999',
                            position: 'fixed',
                            display: 'inline-block',
                            backgroundColor: '#222732',
                            left: '5px',
                            marginBottom: '5px',
                            right: '5px',
                            bottom: '5px',
                            width: 'auto',
                            padding: '20px',
                            borderRadius: '3px',
                            textAlign: 'center'
                        }}
                        buttonStyle={{
                            padding: '5px 10px',
                            display: 'inline',
                            fontSize: '1em',
                            borderRadius: '3px',
                            backgroundColor: '#fff',
                            color: '#7b569f',
                            fontWeight: '400',
                            textTransform: 'uppercase',
                            fontFamily: "Roboto Condensed,sans-serif"
                        }}
                        onAccept={() => {
                            console.log('cookie accepted');
                        }}
                        sameSite="strict"
                        expires={150}
                    >
                        {t('loginPage.cookie.message')}
                        <span id='cookit-message'>
                            <br/>
                            <a href={`${process.env.REACT_APP_COOKIE_POLICY_PATH ? process.env.REACT_APP_COOKIE_POLICY_PATH : "https://bonemonitor.com/cookie"}`}
                               className='mt-3 purple'
                               target="_blank" rel='noreferrer noopener'
                            >{t('loginPage.cookie.learnMore')}</a>
                            </span>

                    </CookieConsent>:null}
                    <AppFooter/>
                </Suspense>
            </div>
    );
}

export default BasicCorporatePage;

/*
 *
 *
 * */