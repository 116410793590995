import * as actionTypes from '../Actions/actionTypes';
import {ChangePathName} from "../../Hooks/ChangePathName/ChangePathName";
const dotProp = require('dot-prop-immutable');

const initialState = {};

const contextHospitalReducer = ( state = initialState, action ) => {
    let e;


    switch ( action.type ) {

        //onchange
        case actionTypes.HOSPITAL_DETAILS_ONCHANGE:
            return dotProp.set(state,`${action.e.name}`,action.e.value);

        case actionTypes.SET_PDF_SIGNATURE:
            return dotProp.set(state,`${action.e.name}`,action.e.value);

        //CRUD OPERATION
        case actionTypes.DELETE_IN_HOSPITAL:
            return dotProp.delete(state, `${action.name}.${action.elemIndex}`);

        case actionTypes.UPDATE_IN_HOSPITAL:
            return dotProp.set(state,`${action.name}.${action.elemIndex}`,action.elem);

        case actionTypes.ADD_IN_HOSPITAL:
            return dotProp.merge( state, `${action.name}`, action.elem);

        case actionTypes.UPDATE_SETTINGS:
            return dotProp.set(state,`${action.name}.${action.elemIndex}.value`, action.elem.value);

        case actionTypes.SET_SELECTED_FILE_IMPORT:
            return dotProp.set(state, ChangePathName(action.name), action.value);

        case actionTypes.SET_SINGLE_LETTER_TEMPLATE:
            e= dotProp.set(state, `generalSettings.templateGroup.${action.groupIndex}.templates.${action.templateIndex}`, {
                ...dotProp.get(state, `generalSettings.templateGroup.${action.groupIndex}.templates.${action.templateIndex}`),
                ...action.singleLetterTemplate
            });
            return dotProp.set(e, `generalSettings.letterTemplate.tempLetterTemplateData`, action.singleLetterTemplate );

        case actionTypes.SET_ALL_LETTER_TEMPLATE:
            return dotProp.set(state, 'generalSettings.templateGroup', action.allLetterTemplate );

        case actionTypes.SET_KPI_TEMP_DATE_RANGE:
            return dotProp.set(state, 'kpi.kpiTempDateRange', {
                dateFrom:action.dateFrom,
                dateTo:action.dateTo,
                relativeTimeSelected:action.relativeTimeSelected,
                relativeTimeNameSelected:action.relativeTimeNameSelected
            } );

        case actionTypes.ON_SAVE_LETTER_TEMPLATE:
            return dotProp.merge(state, `generalSettings.templateGroup.${action.groupIndex}.templates.${action.templateIndex}`,action.data);

        case actionTypes.ON_DELETE_LETTER_TEMPLATE:
            return dotProp.delete(state, `generalSettings.templateGroup.${action.templateGroupIndex}.templates.${action.templateIndex}`);

        case actionTypes.ON_CHANGE_LETTER_TEMPLATE:
            return dotProp.set(state, action.pathName,action.data);

        case actionTypes.TOGGLE_MIDDLE_LETTER_TEMPLATE_MODAL:
            return dotProp.set(state, 'generalSettings.letterTemplate.toggleMiddleModalLetterTemplate',
                {
                    open:action.open,
                    selected:action.selected,
                    name:action.name
                });

        case actionTypes.CREATE_LETTER_TEMPLATE:
            return dotProp.merge(state, `generalSettings.templateGroup.${action.index}.templates`,action.value);

        case actionTypes.SET_TEMP_LETTER_TEMPLATE:
                return dotProp.set(state, 'generalSettings.letterTemplate.tempLetterTemplateData',
                    action.value);

        case actionTypes.TOGGLE_LETTER_TEMPLATE_MODAL:
            return dotProp.set(state, 'generalSettings.letterTemplate.toggleModalLetterTemplate',
                {
                    groupIndex:action.groupIndex,
                    templateIndex:action.templateIndex,
                    open:action.open
                });

        case actionTypes.TOGGLE_LETTER_TEMPLATE_GROUP:
            return dotProp.merge(state, 'generalSettings.letterTemplate.'+action.value.id, {id:action.value.id,open:action.value.open});

        case actionTypes.SET_EXPORT_ERROR_MSG:
            return dotProp.set(state, 'maintenance.export.error', {
                msg:action.msg,
                error:true
            } );

        case actionTypes.SET_EXPORTING_STATUS_DATA:
            return dotProp.merge(state, 'maintenance.export', {
                filePath:action.filePath,
                fileSize:action.fileSize
            } );

        case actionTypes.SET_SETTINGS:
            return dotProp.set(state, 'settings',action.settings );

        case actionTypes.SET_EXPORTING_PATIENT:
            return dotProp.set(state, "maintenance.export.exportingPatient", action.exportingPatient);

        case actionTypes.SETTINGS:
            return dotProp.set(state,`${action.name}.${action.elemIndex}.value`, action.elem.value);

        case actionTypes.UPDATE_HOSPITAL_DETAILS:
            return dotProp.set(state, "generalSettings.hospitalDetails", action.hospitalDetails);

        case actionTypes.IMPORT_BACKEND_ERROR:
            return dotProp.set(state, 'maintenance.importError', action.importError);

        case actionTypes.SET_UPLOAD_CSV:
            return dotProp.set(state, 'maintenance.upload', action.upload);

        case actionTypes.SET_MAINTENANCE_REPORT:
            return dotProp.set(state, 'maintenance.report', action.value);

        case actionTypes.RESET_MAINTENANCE:
            return dotProp.set(state, ChangePathName('maintenance'), action.value);

        case actionTypes.MAINTENANCE_CHANGE:
            return dotProp.set(state, ChangePathName(action.e.name), action.e.value);

        case actionTypes.SET_IMPORTING:
            return dotProp.set(state, ChangePathName('maintenance.importing'), action.value);

        case actionTypes.UPDATE_KPI:
            return dotProp.set(state, ChangePathName('kpi'), {...state.kpi,
                ...action.kpi});

        case actionTypes.SELECTED_KPI:
            return dotProp.set(state, ChangePathName('kpi.selectedKpi'), action.selectedKpi);

        case actionTypes.ROLES_LOADING:
            return dotProp.set(state, ChangePathName('rolesLoading'), action.rolesLoading);

        case actionTypes.PERMISSIONS_LOADING:
            return dotProp.set(state, ChangePathName('permissionsLoading'), action.permissionsLoading);

        case actionTypes.KPI_LOADING:
            return dotProp.set(state, ChangePathName('kpiLoading'), action.kpiLoading);

        case actionTypes.MADE_A_CHANGE_HOSPITAL:
            return dotProp.set(state, ChangePathName('madeAchangeHospital'), action.value);

        case actionTypes.SET_ROLES:
            return dotProp.set(state, ChangePathName('roles'), action.roles);

        case actionTypes.UPDATE_PERMISSIONS:
            return dotProp.set(state, ChangePathName(action.name), action.permissions);

        case actionTypes.SET_PERMISSIONS:
            return dotProp.set(state, ChangePathName('permissions'), action.permissions);

        case actionTypes.SET_RESU_ROLES:
            return dotProp.set(state, ChangePathName('usersRoles'), action.usersRoles);

        case actionTypes.LOADING_BPF_EXPORT:
            return dotProp.set(state, 'maintenance.loadingBpfExport', action.loadingBpfExport);

        case actionTypes.BPF_EXPORT_PATH:
            return dotProp.set(state, 'maintenance.bpfExportPath', action.bpfExportPath);

        default:
            return state;
    }
};

export default contextHospitalReducer;