import * as actionTypes from './actionTypes';
import * as actionCreators from './index';
import {backendURL, backendConfig} from "../utility";
import axios from "axios";

export const closeAllSearchSection =()=>{
    return {
        type:actionTypes.CLOSE_ALL_SEARCH_SECTION,
        toggleSection: {
            criteria: {
                close: true,
                patient: {
                    open: true
                },
                clinical: {
                    close: true,
                    indexFracture: {
                        close: true
                    },
                    frax: {
                        close: true
                    },
                    treatmentRecommendation: {
                        close: true
                    },
                    monitoring: {
                        close: true
                    }
                },
                tasks: {
                    close: true
                }
            },
            output: {
                patient: {
                    close: false
                },
                contactForMonitoring: {
                    close: false
                },
                otherExports: {
                    close: false
                },
                close: true
            }
        }
    };
};

export const openAllSearchSection =()=>{
    return {
        type:actionTypes.OPEN_ALL_SEARCH_SECTION,
    };
};

export const selectSaveSearchModal =(selected)=>{
    return {
        type:actionTypes.SET_SELECTED_SAVE_SEARCH_MODAL,
        saveSearchModalToggle:{
            selected:selected
        }
    };
};

export const searchResultLoading =(loading)=>{
    return {
        type:actionTypes.SEARCH_RESULT_LOADING,
        searchResultLoading:loading
    };
};

export const onSaveSearchModalToggle =(selected)=>{
    return dispatch=>{
        dispatch(actionCreators.resetSaveMsg());
        dispatch(actionCreators.setGeneralSaveMsg("",false));
        return dispatch(saveSearchModalToggle(selected));
    };
};

export const saveSearchModalToggle =(selected)=>{
    return {
        type:actionTypes.TOGGLE_SAVE_SEARCH_MODAL,
        saveSearchModalToggle:{
            selected:selected
        }
    };
};

export const toggleSearchSection=(name)=>{
    return {
        type: actionTypes.TOGGLE_SEARCH_SECTION,
        name:name
    };
};

export const resetSearch=()=>{
    return {
        type: actionTypes.RESET_SEARCH
    };
};

export const getSavedSearch =(searchId,signal)=>{
    return dispatch=>{
        axios.get(`${backendURL}/search/searches/${searchId}`,backendConfig)
            .then(resp=>{
                let obj={
                    description: resp.data.payload.description,
                    id: resp.data.payload.id,
                    name: resp.data.payload.name,
                    ...JSON.parse(resp.data.payload.criteria)
                };
                let searchBy=[];
                let searchOutput=[];
                if(resp.data.payload && resp.data.payload.searchBy){
                     searchBy=[...JSON.parse(resp.data.payload.searchBy)];
                }
                if(resp.data.payload && resp.data.payload.searchOutput){
                    searchOutput=[...JSON.parse(resp.data.payload.searchOutput)];
                }
                dispatch(setSavedSearch(obj,searchBy,searchOutput));
                dispatch(actionCreators.sendSearch(obj,"patientQuery",signal));
            }).catch(error=>{
                if(error && error.response){
                    dispatch(actionCreators.handleBackendError(error.response));
                }else{
                    if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
                }
        });
};};

export const setSavedSearch=(searchData,searchBy,searchOutput)=>{
    return {
        type: actionTypes.SET_SEARCHDATA,
        searchData,searchBy,searchOutput
    };
};

export const getSavedSearchList =(redirect,savedId)=>{
    return dispatch=>{
        axios.get(`${backendURL}/search/searches`,backendConfig)
            .then(resp=>{
                dispatch(setSavedSearchList(resp.data.payload));
                if(redirect) dispatch(actionCreators.redirectToHomePage('/app/search/saved/'+savedId,''));

            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const setSavedSearchList=(savedSearch)=>{
    return {
        type: actionTypes.SET_SEARCHDATALIST,
        savedSearch
    };
};

export const saveSavedSearch =(data)=>{
    return dispatch=>{
        axios.post(`${backendURL}/search/save`,data,backendConfig)
            .then(resp=>{
                dispatch(getSavedSearchList(true,resp.data.payload.id));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };};

export const deleteBackSavedSearch =(id)=>{
    return dispatch=>{
        axios.delete(`${backendURL}/search/delete/${id}`,backendConfig)
            .then(resp=>{
                dispatch(setSavedSearchList(resp.data.payload));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


export const sendSearch =(data,type,signal)=>{
    let newData={
    ...data,
        pageNumber:data.pageNumber?data.pageNumber:0,
        type
    };
    delete newData.id;
    delete newData.name;
    delete newData.description;
    return dispatch=>{
        dispatch(actionCreators.searchResultLoading(true));
        axios.post(`${backendURL}/search/`,newData, {...backendConfig,signal})
            .then(resp=>{
                dispatch(actionCreators.searchResultLoading(false));
                dispatch(actionCreators.setSearchResult());
                if(resp.data.payload.columns===null || resp.data.payload.results.length===0){
                    dispatch(actionCreators.setGeneralSaveMsg('app.noResultFound',true));
                }else{
                    dispatch(actionCreators.setSearchResult(resp.data.payload));
                }
            }).catch(error=>{
                console.log(error);
            dispatch(actionCreators.searchResultLoading(false));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('app.noResultFound',true));
            }
        });
    };};

export const setSearchResult=(searchResult)=>{
    return {
        type: actionTypes.SET_SEARCHRESULT,
        searchResult
    };
};

export const resetSearchResult=()=>{
    return {
        type: actionTypes.RESET_SEARCH_RESULT
    };
};

export const loadingSearchCsv=(loading,ready)=>{
    return {
        type: actionTypes.LOADING_SEARCH_CSV,
        loadingSearchCsv:{
            loading,
            ready
        }
    };
};

export const prepareSearchCSV =(data)=>{
    let newData={
        ...data
    };
    delete newData.id;
    delete newData.name;
    delete newData.description;
    return dispatch=>{
        dispatch(actionCreators.loadingSearchCsv(true,false));
        axios.post(`${backendURL}/search/csv/start`,newData,backendConfig)
            .then(resp=>{
                //dispatch(actionCreators.loadingSearchCsv(false,true));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingSearchCsv(false,false));
        });
    };
};

export const downloadSearchCSV =()=>{
    return dispatch=> {
        window.location.href = `${backendURL}/search/csv/serve`;
    };
};

export const loadingSearchPdf=(loading,ready)=>{
    return {
        type: actionTypes.LOADING_SEARCH_PDF,
        loadingSearchPdf:{
            loading,
            ready
        }
    };
};

export const prepareSearchPDF =(data)=>{
    let newData={
        ...data
    };
    delete newData.id;
    delete newData.name;
    delete newData.description;
    return dispatch=>{
        dispatch(actionCreators.loadingSearchPdf(true,false));
        axios.post(`${backendURL}/search/pdf/start`,newData,backendConfig)
            .then(resp=>{
                //dispatch(actionCreators.loadingSearchPdf(false,true));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingSearchPdf(false,false));

        });
    };
};

export const downloadSearchPDF =()=>{
    return dispatch=> {
        window.location.href = `${backendURL}/search/pdf/serve`;
    };
};

export const madeAchangeSearch=(change)=>{
    return {
        type: actionTypes.MADE_A_CHANGE_SEARCH,
        change
    };
};

export const pathCsvStatus=(pathCsvStatus)=>{
    return {
        type: actionTypes.PATH_CSV_STATUS,
        pathCsvStatus:{
            ...pathCsvStatus
        }
    };
};

export const getCsvStatus =()=>{
       return dispatch=>{
               dispatch(actionCreators.loadingSearchCsv(true,false));
        axios.get(`${backendURL}/search/csv/status`,backendConfig)
            .then(resp=>{
                if(resp.data.payload.processing){
                        dispatch(actionCreators.loadingSearchCsv(true,false));
                }else{
                    dispatch(actionCreators.loadingSearchCsv(false,true));
                    if(resp.data.payload.fileSize>=0 && resp.data.payload.exportFilePath){
                        dispatch(actionCreators.pathCsvStatus({
                            exportFilePath:resp.data.payload.exportFilePath,
                            fileSize:resp.data.payload.fileSize
                        }));
                    }
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingSearchCsv(false,false));

        });
    };
};

export const pathPdfStatus=(pathPdfStatus)=>{
    return {
        type: actionTypes.PATH_PDF_STATUS,
        pathPdfStatus:{
            ...pathPdfStatus
        }
    };
};

export const getPdfStatus =()=>{
    return dispatch=>{
            dispatch(actionCreators.loadingSearchPdf(true,false));
        axios.get(`${backendURL}/search/pdf/status`,backendConfig)
            .then(resp=>{
                if(resp.data.payload.processing){
                    dispatch(actionCreators.loadingSearchPdf(true,false));
                }else{
                    dispatch(actionCreators.loadingSearchPdf(false,true));
                    if(resp.data.payload.fileSize>=0 && resp.data.payload.exportFilePath){
                        dispatch(actionCreators.pathPdfStatus({
                            exportFilePath:resp.data.payload.exportFilePath,
                            fileSize:resp.data.payload.fileSize
                        }));
                    }

                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingSearchPdf(false,false));

        });
    };
};

export const setAllPossibleMonitorings=(moni)=>{
    return {
        type: actionTypes.SET_ALL_POSSIBLE_MONITORINGS,
        moni
    };
};

export const getAllPossibleMonitorings =()=>{
    return dispatch=>{
        axios.get(`${backendURL}/search/monitorings`,backendConfig)
            .then(resp=>{
                dispatch(setAllPossibleMonitorings(resp.data.payload));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const resetSearchBy=()=>{
    return {
        type: actionTypes.RESET_SEARCH_BY
    };
};

export const resetSearchOutput=()=>{
    return {
        type: actionTypes.RESET_SEARCH_OUTPUT
    };
};

export const setSearchOutput=(data)=>{
    return {
        type: actionTypes.SET_SEARCH_OUTPUT,
        data
    };
};

export const setOutput=(data)=>{
    return {
        type: actionTypes.SET_OUTPUT,
        data
    };
};

export const setSearchOutputTemp=(data)=>{
    return {
        type: actionTypes.SET_SEARCH_OUTPUTTEMP,
        data
    };
};










