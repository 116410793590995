import * as actionCreators from './index';
import axios from 'axios';
import {backendURL, backendConfig} from "../utility";


export const getAllFiles =(hid,fileName,selected,transList,name,folder)=>{
    return dispatch=> {
        if(hid){
            axios.get(`${backendURL}/resources/${hid}/${folder}`,backendConfig )
                .then( resp  => {
                    if(resp.data && resp.data.payload){
                        dispatch(actionCreators.onGeneralChange({target: {name: "allFile", value: resp.data.payload}}, 'SET_ALL_FILE'));
                    }
                    if(fileName){
                        let selec=resp.data.payload.filter(elem=>elem.fileName===fileName);

                        let obj={
                            ...selected,
                            val:selec[0].url
                        };
                        let remove=transList.translations.filter(elem=>elem.locale!==obj.locale);
                        remove.push(obj);
                        dispatch(actionCreators.onGeneralChange({target: {name: name, value: remove}}, 'HOSPITAL_DETAILS_CHANGE'));
                    }
                }).catch(error=>{
                if(error && error.response){
                    dispatch(actionCreators.setGeneralSaveMsg(error.response.data.error.message ,true,error.response.data.error.details));
                    dispatch(actionCreators.handleBackendError(error.response));
                }else{
                    if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
                }
                return [];
            });
        }
    };
};


export const postAFile =(hid,file,path,type,askList,openModal,selected,transList,name,folder,updateState)=>{
    let formData = new FormData();
    formData.append("file", file);
    return dispatch=> {
        if(hid && file){
            axios.post(`${backendURL}/resources/${hid}/${folder}`,formData,backendConfig )
                .then( resp  => {
                    if(resp.data && resp.data.payload){
                        if(!askList){
                            dispatch(actionCreators.onGeneralChange({target: {name: path, value: resp.data.payload}}, type));
                        }else dispatch(actionCreators.getAllFiles(hid,resp.data.payload,selected,transList,name,folder));
                    }
                    if(openModal){
                        dispatch(actionCreators.onGeneralModalToggle('selectImgUploadModalToggle',false,false,false,'GENERAL_TOGGLE_MODAL'));
                        dispatch(actionCreators.onGeneralModalToggle('multiImgUploadModalToggle',true,false,false,'GENERAL_TOGGLE_MODAL'));
                    }
                    if(updateState) updateState(resp.data.payload);
                }).catch(error=>{
                if(error && error.response){
                    dispatch(actionCreators.setGeneralSaveMsg(error.response.data.error.message ,true,error.response.data.error.details));
                    dispatch(actionCreators.handleBackendError(error.response));
                }else{
                    if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
                }
                return [];
            });
        }
    };
};


export const deleteFile =(fileName,hid,folder)=>{
    return dispatch=> {
        if(fileName){
            axios.delete(`${backendURL}/resources/${hid}/${folder}/${fileName}`,backendConfig )
                .then( resp  => {
                    dispatch(actionCreators.getAllFiles(hid,false,false,false,false,folder));
                }).catch(error=>{
                if(error && error.response){
                    dispatch(actionCreators.setGeneralSaveMsg(error.response.data.error.message ,true,error.response.data.error.details));
                    dispatch(actionCreators.handleBackendError(error.response));
                }else{
                    if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
                }
                return [];
            });
        }
    };
};
