import * as actionTypes from './actionTypes';
import * as actionCreators from './index';
import axios from "axios";
import {backendURL, backendConfig} from "../utility";


export const getMasterDrugs =()=>{
    return dispatch=> {
        axios.get(`${backendURL}/hospital/settings/masterdrugs`
            ,backendConfig)
            .then( resp  => {
                dispatch(setMasterDrugs(resp.data.payload));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const setMasterDrugs =(drugs)=>{
    return {
        type:actionTypes.SET_MASTER_DRUGS,
        drugs:drugs
    };
};

export const setAntiOsteoMedication =(drugs)=>{
    return {
        type:actionTypes.SET_ANTIOSTEO_MEDICATION,
        drugs:drugs
    };
};

export const changeOnDrugs =(bool,name)=>{
    return {
        type:actionTypes.CHANGE_DRUGS,
        bool,name
    };
};


export const setAntiOsteoMedicationSelected =(drugs)=>{
    return {
        type:actionTypes.SET_ANTIOSTEO_MEDICATION_SELECTED,
        selectedDrugs:drugs
    };
};

export const deleteAntiOsteoMedicationBack =(drugsId,hid)=>{
    return dispatch=> {
        axios.delete(`${backendURL}/hospital/${hid}/settings/drugs/${drugsId}`
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.hospitalUpdated",false));
                dispatch(setAntiOsteoMedicationSelected(resp.data.payload));
                dispatch(changeOnDrugs(false,'hospital.changeSelectedDrugs'));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


export const sendAntiOsteoMedication =(drugs,hid)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/hospital/${hid}/settings/masterDrugs`,
            drugs
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.hospitalUpdated",false));
                dispatch(setAntiOsteoMedication(resp.data.payload));
                dispatch(changeOnDrugs(false,'hospital.changeDrugs'));
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });

    };
};

export const sendSelectedAntiOsteoMedicationPos =(drugs,hid)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/hospital/${hid}/settings/drugPosition `,
            drugs
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.hospitalUpdated",false));
                dispatch(setAntiOsteoMedicationSelected(resp.data.payload));
                dispatch(changeOnDrugs(false,'hospital.changeSelectedDrugs'));
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const setPageDataDrugs =(drugs)=>{
    return {
        type:actionTypes.SET_ANTIOSTEO_MEDICATION,
        drugs:drugs
    };
};

export const sendSelectedAntiOsteoMedication =(drugs,hid)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/hospital/${hid}/settings/selectedDrugs`,
            drugs
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.hospitalUpdated",false));
                if(resp.data.payload.drugHospital){
                    dispatch(setAntiOsteoMedicationSelected(resp.data.payload.drugHospital));
                    dispatch(changeOnDrugs(false,'hospital.changeSelectedDrugs'));
                }
                if(resp.data.payload.drugs){
                    dispatch(setPageDataDrugs(resp.data.payload.drugs));
                    dispatch(changeOnDrugs(false,'hospital.changeDrugs'));
                }

            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const sendAllDrugList =(drugs,hid)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/hospital/${hid}/settings/selectedDrugs`,
            drugs
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.madeAChangeHospital(false));
                dispatch(actionCreators.userMadeAChange(false));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.hospitalUpdated",false));
                if(resp.data.payload.drugHospital){
                    dispatch(setAntiOsteoMedicationSelected(resp.data.payload.drugHospital));
                    dispatch(changeOnDrugs(false,'hospital.changeSelectedDrugs'));
                }
                if(resp.data.payload.drugs){
                    dispatch(setPageDataDrugs(resp.data.payload.drugs));
                    dispatch(changeOnDrugs(false,'hospital.changeDrugs'));
                }
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const postDrugAndSettings =(drugs,hid,settings)=>{
    return dispatch=> {
        dispatch(actionCreators.showSaveLoader(true));
        axios.post(`${backendURL}/hospital/${hid}/settings/selectedDrugs`,
            drugs
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.hospitalUpdated",false));
                dispatch(actionCreators.postSettings(settings,hid));
                if(resp.data.payload.drugHospital){
                    dispatch(setAntiOsteoMedicationSelected(resp.data.payload.drugHospital));
                    dispatch(changeOnDrugs(false,'hospital.changeSelectedDrugs'));
                }
                if(resp.data.payload.drugs){
                    dispatch(setPageDataDrugs(resp.data.payload.drugs));
                    dispatch(changeOnDrugs(false,'hospital.changeDrugs'));
                }
            }).catch(error=>{
            dispatch(actionCreators.showSaveLoader(false));
            dispatch(actionCreators.madeAChangeHospital(false));
            dispatch(actionCreators.userMadeAChange(false));
            dispatch(actionCreators.showSaveBtnAndResetMsg(false));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


