import * as actionTypes from '../Actions/actionTypes';
import {ChangePathName} from "../../Hooks/ChangePathName/ChangePathName";
const dotProp = require('dot-prop-immutable');

const initialState = {};

const errorReducer = ( state = initialState, action ) => {

    switch ( action.type ) {

        case actionTypes.GENERAL_SAVE_MSG:
            return dotProp.set(state, 'generalSaveMsg', {...action.generalSaveMsg});

        case actionTypes.ERROR_FIELD:
            return dotProp.set(state,ChangePathName(action.errorInput.name),action.errorInput.value);

        case actionTypes.PATIENT_DELETED:
            return dotProp.set(state, 'patientDeleted', action.patientDeleted);

        case actionTypes.NOT_FOUND:
            return dotProp.set(state, 'notFound', action.notFound);

        case actionTypes.ERROR_FIELD_BE:
            return dotProp.set(state, 'errorLocation', action.errorLocation);

        case actionTypes.RESET_ERROR_FIELD:
            return dotProp.set(state, 'errorField', {});

        case actionTypes.ERROR_PATIENT:
            return dotProp.set(state, 'msg', {...state.error,
                msg:action.msg,
                patientSaved:action.patientSaved,
                patientIdExist:action.patientIdExist
            });

        case actionTypes.RESET_SAVE_MSG:
            return dotProp.set(state, 'msg', action.msg);

        case actionTypes.SAVE_PATIENT:
            return dotProp.set(state, 'patientSaved', action.patientSaved);

        default:
            return state;
    }
};

export default errorReducer;