import React from 'react';
import {RiLoader5Line} from 'react-icons/ri';
import '../Sass/GeneralStyle/loader.scss';

const MiniLoader=(props)=>{
    return <RiLoader5Line size={props.size?props.size:100} className={'purple icn-spinner  centerMini ' + props.color}/>;

};

export default MiniLoader;
