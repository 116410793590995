import * as actionTypes from './actionTypes';
import * as actionCreators from "./index";
import axios from "axios";
import {backendConfig, backendURL} from "../utility";



export const onGeneralChange=(e,typeAct)=>{
    return {
        type: actionTypes[typeAct],
        e: {
            name: e.target.name,
            value: e.target.value
        }
    };
};

export const setDefaultToArr=(name,typeAct)=>{
    return {
        type: actionTypes[typeAct],
        e: {
            name,
            value: []
        }
    };
};

export const onGeneralModalToggle =(name,open,selected,index,typeAct,msg,nameId)=>{
    return dispatch=>{
        dispatch(actionCreators.resetSaveMsg());
        dispatch(actionCreators.setGeneralSaveMsg("",false));
        return dispatch(generalModalToggle(name,open,selected,index,typeAct,msg,nameId));
    };
};

export const generalModalToggle=(name,open,selected,index,typeAct,msg,nameId)=>{
    return {
        type: actionTypes[typeAct],
        name,open,selected,index,msg,nameId
    };
};


export const generalDelete=(name,categorieIndex,elemIndex,typeAct,isArr)=>{
    return {
        type: actionTypes[typeAct],
        name,categorieIndex,elemIndex,isArr
    };
};

export const generalAdd=(name,categorieIndex,elemIndex,elem,typeAct,isArr)=>{
    return {
        type: actionTypes[typeAct],
        name,categorieIndex,elemIndex,elem,isArr
    };
};

export const generalUpdate=(name,categorieIndex,elemIndex,elem,typeAct,isArr)=>{
    return {
        type: actionTypes[typeAct],
        name,categorieIndex,elemIndex,elem,isArr
    };
};

export const generalDownloadPath =(path)=>{
    return dispatch=> {
        window.location.href = path;
    };
};

export const sendScreenShot =(image,path,name,typeAct)=>{
    return dispatch=> {
        dispatch(actionCreators.isLoading(true,false,name,typeAct));
        axios.post(`${backendURL}${path}`,
            image
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.isLoading(true,false,name,typeAct));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });

    };
};

export const isLoading=(loading,ready,name,typeAct)=>{
    return {
        type: actionTypes[typeAct],
        isLoading:{loading,ready,name}
    };
};

export const getGeneralStatus =(path,servePath,name,typeAct)=>{
    return dispatch=>{
        dispatch(actionCreators.isLoading(true,false,name,typeAct));
        axios.get(`${backendURL}${path}`,backendConfig)
            .then(resp=>{
                if(resp.data.payload.processing){
                    dispatch(actionCreators.isLoading(true,false,name,typeAct));
                }else{
                    window.location.href = `${backendURL}${servePath}`;
                    /*axios.get(`${backendURL}${servePath}`,backendConfig)
                        .then(resp=>console.log(resp))
                        .catch(err=>console.log(err));*/
                    dispatch(actionCreators.isLoading(false,true,name,typeAct));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.isLoading(false,false,name,typeAct));
        });
    };
};



export const toggleGeneral=(toggle=false,typeAct,path)=>{
    return {
        type: actionTypes[typeAct],
        toggle,path
    };
};

export const setGeneralArray=(array,path,typeAct)=>{
    return {
        type: actionTypes[typeAct],
        array,path
    };
};





