import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actionCreators from './index';
import {backendURL, backendConfig} from "../utility";


export const postSettings=(settings,hospitalId,reload)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/hospital/${hospitalId}/settings`,settings,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.madeAChangeHospital(false));
                dispatch(actionCreators.userMadeAChange(false));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(setSettings(resp.data.payload));
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.settingsUpdated",false));
                if(reload) window.location.reload();

            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            dispatch(actionCreators.madeAChangeHospital(false));
            dispatch(actionCreators.userMadeAChange(false));
            dispatch(actionCreators.showSaveBtnAndResetMsg(false));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const setSettings=(settings)=>{
    return {
        type: actionTypes.SET_SETTINGS,
        settings:settings
    };
};

export const getSettings=(hospitalId)=>{
    return dispatch=> {
        axios.get(`${backendURL}/hospital/${hospitalId}/settings`,backendConfig)
            .then( resp  => {
                dispatch(setSettings(resp.data.payload));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};