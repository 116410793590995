import * as actionTypes from './actionTypes';
import * as actionCreators from './index';
import axios from "axios";
import {backendURL, backendConfig} from "../utility";

export const setMachines =(data)=>{
    return {
        type:actionTypes.SET_MACHINES,
        machinesFiltered:data.filter(machine=>!machine.archived),
    };
};

export const setMachinesMaster =(data)=>{
    return {
        type:actionTypes.SET_MACHINES_MASTER,
        machines:data
    };
};



export const postMachines =(data,hospitalId)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/hospital/${hospitalId}/machines`,
            data
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.resetField(true));
                dispatch(actionCreators.madeAChangeHospital(false));
                dispatch(actionCreators.userMadeAChange(false));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.hospitalUpdated",false));
                dispatch(setMachines(resp.data.payload));
                dispatch(setMachinesMaster(resp.data.payload));
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};
