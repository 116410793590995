import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';

i18n
    .use(Backend)
    .use(ICU)
    .use(LanguageDetector)
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react!!
            skipOnVariables: false
        },
        // Language detector options: see https://github.com/i18next/i18next-browser-languageDetector
        detection: {
            order: ['navigator'],
            caches: []
        },
        ns:['AppTranslations','kpiRef'],
        defaultNS:['AppTranslations'],

        // See documented options https://github.com/i18next/i18next-http-backend
        backend: {

            // Load .properties files
            loadPath: lng => {
                if(`${lng}` === 'en'){
                    // For 'en', serve default file
                    return `/locales/translations_icu.properties`;
                }
                else {
                    // For others, try to load a specific file. E.g.: translations_icu_fr.properties
                    return `/locales/translations_icu_${lng}.properties`;
                }
            },

            // Transform .properties to JavaScript objects.
            parse: data => parsePropertiesFile(data)
        }

        // react i18next special options (optional)
        // override if needed - omit if ok with defaults
        /*
        react: {
          bindI18n: 'languageChanged',
          bindI18nStore: '',
          transEmptyNodeValue: '',
          transSupportBasicHtmlNodes: true,
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
          useSuspense: true,
        }
        */
    });

// https://espressoprogrammer.com/migrate-jquery-i18n-properties-i18next/
const parsePropertiesFile = (properties) => {
    return properties.split('\n')
        .filter(line => '' !== line.trim())
        /*.map(line => line.split('='))
        .map(tokens => ({
            [tokens[0]]: tokens[1]
        }))*/
        .map(line => ({
            [line.substring(0, line.indexOf('='))]: line.substring(line.indexOf('=')+1)
        }))
        .reduce((properties, property) => ({
            ...properties,
            ...property
        }), {});
};

export default i18n;