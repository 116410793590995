import * as actionTypes from '../Actions/actionTypes';
import {ChangePathName} from "../../Hooks/ChangePathName/ChangePathName";
const dotProp = require('dot-prop-immutable');

const initialState = {};


const contextPatientReducer = ( state = initialState, action ) => {
    //Avoid warning "Unexpected lexical declaration in case block  no-case-declarations" by declaring all vars here
    let  singleLetter,l,m,a,b,patientInfoCountry,primaryCareCountry, contactCountry,emptyContextPatient,createCA,createdCA,newCA;
    //handle null value (dotprop doesnt handle it)
    const changeNullValue=(name,state)=>{
        let updatedName=ChangePathName(name);
        let arr=updatedName.split('.');
        let newName=arr[0];
        let newState=state;
        for (var i=1;i<arr.length;i++){
            newName=newName+'.'+arr[i];
            if(dotProp.get(state, newName)===undefined || dotProp.get(state, newName)===null){
                if(Number(arr[i+1])>=0){
                    newState=dotProp.set(newState, newName, [{}]);
                }else{
                    newState=dotProp.set(newState, newName, {});
                }
            }
        }
        return newState;
    };


    switch ( action.type ) {

        case actionTypes.RESET_CLINICAL:
            return dotProp.set(state,`contextPatient.clinicalContent`, {
                ...dotProp.get(state,`contextPatient.clinicalContent`),
                ...action.resetedClinical.clinicalContent
            });

        case actionTypes.DELETE_CA:
            newCA=dotProp.delete(state,`contextPatient.clinicalContent.clinicalAssessment.${action.selected}`);
            return dotProp.set(newCA,
                `caIndex`,
                dotProp.get(newCA,`contextPatient.clinicalContent.clinicalAssessment`).length-1>0
                ?dotProp.get(newCA,`contextPatient.clinicalContent.clinicalAssessment`).length-1
                :0
            );

        case actionTypes.UPDATE_CA:
            return dotProp.set(state,`caIndex`,action.caIndex);

        case actionTypes.CREATE_CA:
            createCA=dotProp.merge( state, `contextPatient.clinicalContent.clinicalAssessment`, {});
            createdCA=dotProp.set( createCA,
                `caIndex`,
                dotProp.get(createCA,`contextPatient.clinicalContent.clinicalAssessment`).length-1);
            /*newPrecFract= dotProp.set( createdCA,
                `contextPatient.clinicalContent.clinicalAssessment.${dotProp.get(createdCA,`caIndex`)}.previousFractures`,
                action.prevFract);*/
            return dotProp.set( createdCA,
                `contextPatient.clinicalContent.clinicalAssessment.${dotProp.get(createdCA,`caIndex`)}.date`,
                action.date);

            //CRUD OPERATION
        case actionTypes.DELETE_IN_PATIENT:
            return dotProp.delete(state, `${action.name}.${action.elemIndex}`);

        case actionTypes.UPDATE_IN_PATIENT:
            return dotProp.set(state,`${action.name}.${action.elemIndex}`,action.elem);

        case actionTypes.ADD_IN_PATIENT:
            return dotProp.merge( state, `${action.name}`, action.elem);

        case actionTypes.ADD_PLANNED_MONITORING:
            if(!dotProp.get(state,action.path+'.0')){
                a=dotProp.set(state,action.path,[]);
                return dotProp.merge(a,action.path,action.value);
            }else{
                return dotProp.merge(state,action.path,action.value);
            }

        case actionTypes.DONT_KNOW_TO_ALL_LABTEST:
            return dotProp.set(state,`contextPatient.clinicalContent.laboratoryTestResults`,action.tests);

        case actionTypes.ADD_MONITORING_DRUGS:
            return dotProp.merge(state,`${ChangePathName(action.e.name.check2)}`,action.e.value);

        case actionTypes.DELETE_MONITORING_DRUGS:
            return dotProp.delete(state,`${ChangePathName(action.e.name)}.${action.e.value}`);

        case actionTypes.ALL_LETTER_LOADING:
            return dotProp.set(state,`allLetterLoading`,action.allLetterLoading);

        case actionTypes.NO_TO_ALL_MONITORING:
            return  dotProp.merge(state, `showMonitoringsList.${action.index}.monitored.currentMobility`, action.value);

        case actionTypes.NO_TO_ALL_FALL:
            return  dotProp.set(state, 'contextPatient.clinicalContent.treatmentRecommendations.0.fallsInterventionRecommendation', action.fallInter);

        case actionTypes.ADD_PATIENT_MODE:
            return dotProp.set(state, 'addPatientMode', action.addPatientMode);

        case actionTypes.RESET_PATIENT:
            emptyContextPatient=dotProp.set(state, 'contextPatient', {});
            patientInfoCountry=dotProp.set(changeNullValue('contextPatient.administrative.patientInformation.addresses.0.address.country',emptyContextPatient)
                , 'contextPatient.administrative.patientInformation.addresses.0.address.country', action.country);
            primaryCareCountry=dotProp.set(changeNullValue('contextPatient.administrative.primaryCarePhysician.addresses.0.address.country',patientInfoCountry)
                , 'contextPatient.administrative.primaryCarePhysician.addresses.0.address.country',  action.country);
            contactCountry=dotProp.set(changeNullValue('contextPatient.administrative.contactForMonitoring.addresses.0.address.country',primaryCareCountry) ,
                'contextPatient.administrative.contactForMonitoring.addresses.0.address.country', action.country);
            return contactCountry;

        case actionTypes.ADD_PATIENT:
            return  dotProp.set(state, 'contextPatient', action.contextPatient);

        //GET PATIENT
        case actionTypes.SET_PATIENT:
            return  dotProp.set(state, 'contextPatient', {
                ...state.contextPatient,
                administrative:{...action.administrative},
                clinicalContent:{...action.clinicalContent},
            });

            //ON CHANGE
        case actionTypes.PATIENT_CHANGE:
           return  dotProp.set(changeNullValue(action.e.name,state), ChangePathName(action.e.name), action.e.value);

        case actionTypes.MADE_A_CHANGE:
            return  dotProp.set(state, 'madeAchange', action.madeAchange);

        //LAB TEST
        case actionTypes.DELETE_LABTEST:
            return dotProp.delete(state, `${action.name}.${action.categorieIndex}.testResults.${action.elemIndex}`);

        case actionTypes.ADD_LABTEST:
            return dotProp.merge(state, `${action.name}.${action.categorieIndex}.testResults`, action.elem);

        case actionTypes.UPDATE_LABTEST:
            return dotProp.merge(state, `${action.name}.${action.categorieIndex}.testResults.${action.elemIndex}`,action.elem);

        // task patient
        case actionTypes.SET_TASK_BY_PATIENT:
            b=dotProp.set(state, 'patientTaskPagiInfo', action.patientTaskPagiInfo);
            return dotProp.set(b, 'contextPatient.tasks', action.tasks);

        //letters
        case actionTypes.TOGGLE_LETTER:
            return dotProp.set(state, ChangePathName(action.path), action.value);

        case actionTypes.RESET_TOGGLE_LETTER:
            return dotProp.set(state, 'letterToggle', {});

        case actionTypes.CREATE_LETTER:
            return dotProp.set(state, 'editLetter', {...action.letter});

        case actionTypes.TOGGLE_LETTER_MODAL:
            l=dotProp.set(state, 'toggleModalLetter', {
                open:action.open,
                name:action.name
            });
            return dotProp.set(l, 'openLetter', {
                letterId:'',
                name:''
            });

        case actionTypes.ON_DELETE_LETTER:
            return dotProp.delete(state, `contextPatient.letters.${action.letterGroupIndex}.letters.${action.letterIndex}`);

        case actionTypes.ON_CHANGE_LETTER:
            return dotProp.set(state, ChangePathName('editLetter.letterText'), action.letterText);

        case actionTypes.OPEN_LETTER:
            return dotProp.set(state, ChangePathName('openLetter'), {
                letterId:action.letterId,
                name:action.name,
                letterGroupId:action.letterGroupId
            });

        case actionTypes.EDIT_LETTER:
            singleLetter=()=>{
                if(state.contextPatient && state.contextPatient.letters){
                    for (let i=0;i<state.contextPatient.letters.length;i++){
                        if(action.groupId===state.contextPatient.letters[i].id){
                            if(state.contextPatient.letters[i].letters && state.contextPatient.letters[i].letters.length){
                                for (let j=0;j<state.contextPatient.letters[i].letters.length;j++){
                                    if (state.contextPatient.letters[i].letters
                                        && state.contextPatient.letters[i].letters[j].id===action.letterId){
                                        return {
                                            name:state.contextPatient.letters[i].name,
                                            info: state.contextPatient.letters[i].letters[j]
                                        };
                                    }
                                }
                            }
                        }
                    }
                }
                return false;
            };
            let obj=singleLetter()?{
                editLetter: {
                    ...singleLetter().info,
                    ...action.resp
                },
                toggleModalLetter:{
                    open:true,
                    name:singleLetter().name
                }}:{
                editLetter: {
                    id:'',
                    communicationGroup:{
                        id:''
                    }
                },
                toggleModalLetter:{
                    open:false,
                    name:''
                }};
            m=dotProp.set(state,'editLetter' , {...obj.editLetter});
            return dotProp.set(m,'toggleModalLetter' , {...obj.toggleModalLetter});

        case actionTypes.UPDATE_ALL_LETTERS:
            return dotProp.set(state,'contextPatient.letters' , action.letters);

            //monitoring

        case actionTypes.CHANGE_MONITORING:
            return dotProp.set(state, ChangePathName(action.name), action.value);

        case actionTypes.RESET_CHANGE_MONITORING:
            return dotProp.set(state,'changeMonitoring' , {});

        case actionTypes.SHOW_MONI_LIST:
            return dotProp.set(state, action.name, action.list);

        case actionTypes.DEFAULT_ARR_MONI:
            return dotProp.set(state, action.e.name, action.e.value);
        default:
            return state;
    }
};

export default contextPatientReducer;