import * as actionTypes from './actionTypes';
import * as actionCreators from "./index";

export const setBlindMode = (mode) => {
    return {
        type: actionTypes.SET_BLIND_MODE,
        mode
    };
};


export const setMode = (mode) => {
    return {
        type: actionTypes.SET_MODE,
        mode
    };
};


export const saved = (saved) => {
    return {
        type: actionTypes.SAVED,
        saved
    };
};

export const openSidebar = (data) => {
    return {
        type: actionTypes.OPEN_SIDEBAR,
        sideBarOpen:data
    };
};


export const toggleSidebar =(toggle)=>{
    return {
        type: actionTypes.TOGGLE_SIDEBAR,
        sideBarOpen:toggle
    };
};

export const resetField =(bool)=>{
    return {
        type: actionTypes.RESET_FIELD,
        resetField:bool
    };
};

export const loading =(val)=>{
    return {
        type: actionTypes.IS_LOADING,
        loading:val
    };
};

export const hospitalLoading =(val)=>{
    return {
        type: actionTypes.HOSPITAL_LOADING,
        hospitalLoading:val
    };
};

export const showSaveBtn =(show)=>{
    return {
        type: actionTypes.SHOW_SAVE_BTN,
        showSaveBtn:show
    };
};

export const showSaveBtnAndResetMsg =(show)=>{
    return dispatch => {
        dispatch(actionCreators.setGeneralSaveMsg("",false));
        dispatch(showSaveBtn(show));
        return dispatch(resetSaveMsg());
    };
};

export const resetSaveMsg =()=>{
    return {
        type: actionTypes.RESET_SAVE_MSG,
        msg:"",
    };
};

export const dateFormat =(dateFormat)=>{
    return {
        type: actionTypes.DATE_FORMAT,
        dateFormat
    };
};

export const showSaveLoader =(showSaveLoader)=>{
    return {
        type: actionTypes.SHOW_SAVE_LOADER,
        showSaveLoader
    };
};

export const mustRefresh = (mustRefresh) => {
    return {
        type: actionTypes.MUST_REFRESH,
        mustRefresh
    };
};