import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actionCreators from './index';
import i18n from "../../i18n";
import {backendURL, backendConfig} from "../utility";
import * as Sentry from "@sentry/browser";

export const resetPdfSignature=()=>{
    return {
        type:actionTypes.SET_PDF_SIGNATURE,
        e:{
            name:"pdfSignature",
            value:{}
        }
    };
};

export const exportingStatusData=(filePath,size)=>{
    return {
        type:actionTypes.SET_EXPORTING_STATUS_DATA,
        fileSize: size,
        filePath:filePath
    };
};

export const setExportErrorMsg=(msg,err)=>{
    return {
        type:actionTypes.SET_EXPORT_ERROR_MSG,
        msg: msg,
        error:err
    };
};

export const exportStatus =()=>{
    return dispatch=> {
        axios.get(`${backendURL}/export/oxford/status`,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.exportingStatusData(resp && resp.data && resp.data.payload && resp.data.payload.exportFilePath,
                    resp && resp.data && resp.data.payload && resp.data.payload.fileSize
                    ));
                dispatch(actionCreators.exportingPatient(resp && resp.data && resp.data.payload && resp.data.payload.processing));
                if(!resp.data.payload.processing){
                    if(resp.data.payload.error){
                        dispatch(actionCreators.setExportErrorMsg(resp.data.payload.error,true));
                    }
                }
            }).catch(error=>{
            dispatch(actionCreators.exportingPatient(false));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const exportStatusLyosis =()=>{
    return dispatch=> {
        axios.get(`${backendURL}/export/lyosis/status`,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.exportingStatusData(resp && resp.data && resp.data.payload && resp.data.payload.exportFilePath,
                    resp && resp.data && resp.data.payload && resp.data.payload.fileSize
                ));
                dispatch(actionCreators.exportingPatient(resp && resp.data && resp.data.payload && resp.data.payload.processing));
                if(!resp.data.payload.processing){
                    if(resp.data.payload.error){
                        dispatch(actionCreators.setExportErrorMsg(resp.data.payload.error,true));
                    }
                }
            }).catch(error=>{
            dispatch(actionCreators.exportingPatient(false));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


export const exportingPatient =(exporting)=>{
    return {
        type:actionTypes.SET_EXPORTING_PATIENT,
        exportingPatient: exporting
    };
};


export const exportPatient =()=>{
    return dispatch=> {
        dispatch(actionCreators.exportingPatient(true));
        dispatch(actionCreators.setGeneralSaveMsg("",false));
        axios.post(`${backendURL}/export/oxford`,'',backendConfig)
            .then( resp  => {
                dispatch(actionCreators.exportingPatient(false));
                //dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.patientExported",false));
            }).catch(error=>{
            dispatch(actionCreators.exportingPatient(false));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const exportPatientLyosis =()=>{
    return dispatch=> {
        dispatch(actionCreators.exportingPatient(true));
        dispatch(actionCreators.setGeneralSaveMsg("",false));
        axios.post(`${backendURL}/export/lyosis/start`,'',backendConfig)
            .then( resp  => {
                dispatch(actionCreators.exportingPatient(false));
                //dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.patientExported",false));
            }).catch(error=>{
            dispatch(actionCreators.exportingPatient(false));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


export function getHospitalDetails(hospitalId) {
    return dispatch=> {
        axios.get(`${backendURL}/hospital/${hospitalId}`,backendConfig)
            .then( resp  => {
                Sentry.setTag("uuid", resp.data.payload.uuid
                    ?resp.data.payload.uuid
                    :"no uuid received");
                dispatch(actionCreators.hospital(resp.data.payload));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
}

function sendHospitalDetailsWithoutImage(hospitalDetails,hospitalId) {
    return axios.post(`${backendURL}/hospital/${hospitalId}`,
        hospitalDetails
        ,backendConfig);
}

function sendHospitalCover(hospitalDetails,hospitalId) {
    let formData = new FormData();
    if(hospitalDetails.newCover){
        formData.append("newCover", hospitalDetails.newCover);
        return axios.post(`${backendURL}/hospital/${hospitalId}/cover`,
            formData
            ,backendConfig);
    }else{
        return true;
    }

}

function sendHospitalLogo(hospitalDetails,hospitalId) {
    let formData = new FormData();
    if(hospitalDetails.newLogo){
        formData.append("newLogo", hospitalDetails.newLogo);
        return axios.post(`${backendURL}/hospital/${hospitalId}/logo`,
            formData
            ,backendConfig);
    }else{
        return true;
    }
}

export const sendHospitalDetails =(hospitalDetails,hospitalId,wizard)=>{
    return dispatch=>{
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.all([sendHospitalDetailsWithoutImage(hospitalDetails,hospitalId),
           //sendHospitalCover(hospitalDetails,hospitalId),
            //sendHospitalLogo(hospitalDetails,hospitalId)
        ])
            .then(results=> {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.madeAChangeHospital(false));
                dispatch(actionCreators.userMadeAChange(false));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                const respDetails = results[0];
                let hospDetails=respDetails.data.payload;
                delete hospDetails.profile;
                dispatch(actionCreators.hospital({...hospDetails, machines:hospDetails.machines.filter(machine=>!machine.archived)}));
                if(wizard){
                    dispatch(actionCreators.redirectToHomePage('/',''));
                }else{
                    dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.hospitalUpdated",false));
                }
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                if(wizard){
                    dispatch(actionCreators.redirectToHomePage(false,error.response.data.error.message + ' : '+error.response.data.error.details));
                }else{
                    dispatch(actionCreators.handleBackendError(error.response));
                }
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


export const postSettingsAndHospitalDetails =(hospitalDetails,hospitalId,wizard,settings)=>{
    return dispatch=>{
        dispatch(actionCreators.showSaveLoader(true));
        axios.all([sendHospitalDetailsWithoutImage(hospitalDetails,hospitalId),
            sendHospitalCover(hospitalDetails,hospitalId),
            sendHospitalLogo(hospitalDetails,hospitalId)])
            .then(results=> {
                dispatch(actionCreators.postSettings(settings,hospitalId));
                const respDetails = results[0];
                let hospDetails=respDetails.data.payload;
                delete hospDetails.profile;
                dispatch(actionCreators.hospital({...hospDetails, machines:hospDetails.machines.filter(machine=>!machine.archived)}));
                if(wizard){
                    dispatch(actionCreators.redirectToHomePage('/',''));
                }else{
                    dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.hospitalUpdated",false));
                }
            }).catch(error=>{
            dispatch(actionCreators.madeAChangeHospital(false));
            dispatch(actionCreators.userMadeAChange(false));
            dispatch(actionCreators.showSaveBtnAndResetMsg(false));
            dispatch(actionCreators.showSaveLoader(false));
            if(error && error.response){
                if(wizard){
                    dispatch(actionCreators.redirectToHomePage(false,error.response.data.error.message + ' : '+error.response.data.error.details));
                }else{
                    dispatch(actionCreators.handleBackendError(error.response));
                }
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


export const updateHospitalDetails =(hospitalDetails)=>{
    return {
        type:actionTypes.UPDATE_HOSPITAL_DETAILS,
        hospitalDetails: hospitalDetails
    };
};

const CancelToken = axios.CancelToken;
const source = CancelToken.source();


export const cancelImport =()=>{
    return dispatch=> {
        axios.get(`${backendURL}/import/oxford/cancel`,backendConfig)
            .then( resp  => {
               //
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};




export const onPrintReport =()=>{
    return dispatch=> {
        window.location.href = `${backendURL}/import/oxford/status/export`;
    };
};



export const uploadCsvProgress =(loaded,total)=>{
    return {
        type:actionTypes.SET_UPLOAD_CSV,
        upload: {
            loaded:loaded,
            total:total
        }
    };
};

export const cancelUpload =()=>{
    source.cancel('Operation canceled by the user.');
    return dispatch=>{
        dispatch(actionCreators.uploadCsvProgress(0,0));
    };
};


export const uploadCsvFile =(file)=>{
    let formData = new FormData();
    formData.append("file", file);
    const params = new URLSearchParams();
    return dispatch=> {
        const uploadProgress = {
            onUploadProgress: (ProgressEvent) => {
                dispatch(actionCreators.uploadCsvProgress(ProgressEvent.loaded,ProgressEvent.total));
                if(ProgressEvent.loaded===ProgressEvent.total && ProgressEvent.total!==0){
                    dispatch(actionCreators.uploadCsvProgress(0,0));
                    dispatch(actionCreators.setImporting(true));
                }
            },
            cancelToken: source.token
        };
        const configPlusProgress = Object.assign(uploadProgress, backendConfig);
        axios.post(`${backendURL}/import/oxford/upload`,
            formData
            , {...configPlusProgress,
            params:params})
            .then( resp  => {
                if(resp.data){
                    dispatch(actionCreators.setImporting(true));
                    dispatch(actionCreators.setMaintenanceReport({...resp.data.payload}));
                }else{
                    dispatch(actionCreators.resetMaintenance());
                    dispatch(actionCreators.importBackendError("error"));
                }
            }).catch(error=>{
            dispatch(actionCreators.resetMaintenance());
            if(error && error.response &&  error.response.data){
                    dispatch(actionCreators.setImporting(false));
                    let errM = "";
                    if(error.response.data.error){
                        if(error.response.data.error.message){
                            errM += " " + error.response.data.error.message;
                        }
                        if(error.response.data.error.details){
                            errM += " " + error.response.data.error.details;
                        }
                        if(error.response.data.error.field){
                            errM += " " + error.response.data.error.field;
                        }
                    }
                    dispatch(actionCreators.importBackendError(errM));
                    dispatch(actionCreators.handleBackendError(error.response));
                }else{
                    dispatch(actionCreators.importBackendError("error"));
                }
        });
    };
};

export const importBackendError =(message)=>{
    return {
        type:actionTypes.IMPORT_BACKEND_ERROR,
        importError: message
    };
};

export const importFileFromServer =()=>{
    const params = new URLSearchParams();

    return dispatch=> {
        dispatch(actionCreators.setImporting(true));
        axios.post(`${backendURL}/import/oxford`,
            ""
            , {
            ...backendConfig,
               params:params
        })
            .then( resp  => {
                if(resp.data){
                    dispatch(actionCreators.setImporting(true));
                    dispatch(actionCreators.setMaintenanceReport({...resp.data.payload}));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.resetMaintenance());
                dispatch(actionCreators.importBackendError(error.response.data.error.message + ' : '+error.response.data.error.details));
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const getStatus =()=>{
    return dispatch=> {
        axios.get(`${backendURL}/import/oxford/status`,backendConfig)
            .then( resp  => {
                if( resp && resp.data&& resp.data.payload.status.total>0){
                    dispatch(actionCreators.setImporting(true));
                    dispatch(actionCreators.setMaintenanceReport({...resp.data.payload}));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
                dispatch(actionCreators.setImporting(false));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


export const setMaintenanceReport =(value)=>{
    return {
        type:actionTypes.SET_MAINTENANCE_REPORT,
        value: value
    };
};

export const setSelectedFile =(value,name)=>{
    return {
        type:actionTypes.SET_SELECTED_FILE_IMPORT,
        value: value,
        name:name
    };
};
export const setSelectedFileHospDetails =(value,name)=>{
    return {
        type:actionTypes.SET_SELECTED_FILE,
        value: value,
        name:name
    };
};

export const resetMaintenance =()=>{
    return {
        type:actionTypes.RESET_MAINTENANCE,
        value: {
            importOrUpload:'',
            selectedFile:false,
            importing:false,
            importError:false,
            upload:{
                loaded:0,
                total:0
            },
            report:{
                status: {
                    processed:0,
                    total:0,
                    success:0,
                    warning:0,
                    error:0,
                    timestamp:"",
                },
                reportGrids:[]
            }
        }
    };
};

export const setImporting =(value)=>{
    return {
        type:actionTypes.SET_IMPORTING,
        value:value
    };
};

export const sendRoles =(obj,uid)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/hospital/${uid}/settings/roles`,
            obj
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.madeAChangeHospital(false));
                dispatch(actionCreators.userMadeAChange(false));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(setRoles(resp.data));
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.hospitalUpdated",false));
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const userManagementResetErrorError =(value)=>{
    return {
        type:actionTypes.RESET_MANAGEMENT_ERROR,
        value:value
    };
};
export const userManagementError =(value)=>{
    return {
        type:actionTypes.MANAGEMENT_ERROR,
        value:value
    };
};

export const showManageSaveBtn =(value)=>{
    return {
        type:actionTypes.SHOW_SAVE_MANAGEMENT_BTN,
        value:value
    };
};

export const resetPageUserInfo =()=>{
    return {
        type:actionTypes.ON_RESET_PAGE_RESU_INFO
    };
};

export const userManagementChange =(value,name)=>{
    return {
        type:actionTypes.ON_CHANGE_MANAGEMENT,
        value:value,
        name:name
    };
};

export const userManagementChangeOther =(value,name,id)=>{
    return {
        type:actionTypes.ON_CHANGE_MANAGEMENT_OTHER,
        value:value,
        name:name,
        id:id
    };
};

export const userInfoChangeOther =(value,name,id)=>{
    return {
        type:actionTypes.ON_CHANGE_INFO_OTHER,
        value:value,
        name:name,
        id:id
    };
};


export const userMadeAChange =(val)=>{
    return {
        type:actionTypes.RESU_MADE_CHANGE,
        userMadeAChange: val
    };
};



export const passwordChangedReset =()=>{
    return {
        type:actionTypes.DWORDSSAP_CHANGE_RESET,
    };
};

export const madeAChangeHospital =(value)=>{
    return {
        type:actionTypes.MADE_A_CHANGE_HOSPITAL,
        value:value
    };
};


export const changePassword =(obj,addNewUser)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/user/${obj.id?obj.id:''}`,
            obj
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.resetField(true));
                dispatch(actionCreators.madeAChangeHospital(false));
                dispatch(actionCreators.userMadeAChange(false));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                setTimeout(()=>{
                    dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.accountUpdated",false));
                }, 500);
                dispatch(actionCreators.resetPageUserInfo());
                if(resp.data.payload.logoutRequired && !addNewUser){
                    //  sessionStorage.removeItem('user');
                    dispatch(actionCreators.logout());
                    dispatch(actionCreators.logoutBackEnd());
                    dispatch(actionCreators.timedOut(true));
                    dispatch(actionCreators.errorLogin(null));
                    dispatch(actionCreators.login(false, null));
                }
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                setTimeout(()=>{
                    dispatch(actionCreators.handleBackendError(error.response));
                }, 500);
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};



export const sendUserInfo =(userInfo,addNewUser,mainUserLang,currentUserId)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/user/${userInfo.id?userInfo.id+'/':''}account`,
            userInfo
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.resetField(true));
                dispatch(actionCreators.madeAChangeHospital(false));
                dispatch(actionCreators.userMadeAChange(false));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                setTimeout(()=>{
                    dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.accountUpdated",false));
                }, 2500);

                dispatch(actionCreators.newUserId(resp.data.payload.id));
                dispatch(actionCreators.addNewUser(false));
                //relog the user when change account data just to get new pagedata(update language list)
                if(userInfo.id === currentUserId){
                    if(!addNewUser
                        &&( userInfo.person.language && userInfo.person.language.value)
                        && userInfo.person.language.value!==mainUserLang
                        && !resp.data.payload.logoutRequired){
                        //let cred=JSON.parse(sessionStorage.credentials);
                        setTimeout(()=>{
                            dispatch(actionCreators.userChange(null/*cred.username*/,null/*cred.password*/,true));
                        }, 1000);
                    }
                }
                if(resp.data.payload.logoutRequired && !addNewUser){
                    sessionStorage.removeItem('user');
                    dispatch(actionCreators.logout());
                    dispatch(actionCreators.logoutBackEnd());
                    dispatch(actionCreators.timedOut(true));
                    dispatch(actionCreators.errorLogin(null));
                    //dispatch(actionCreators.login(false, null));
                }

                //i18n.changeLanguage(resp.data.payload.person.language.value);
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                setTimeout(()=>{
                    dispatch(actionCreators.handleBackendError(error.response));
                }, 2500);
            }else{
                if(error.status <200 && error.status >299 ){
                    setTimeout(()=>{
                        dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
                    }, 2500);
                }
            }
        });
    };
};

export const sendMode =(mode,id)=>{
    return dispatch=> {
        axios.post(`${backendURL}/user/${id?id:''}/settings`,
            mode
            ,backendConfig)
            .then( resp  => {
               console.log('mode saved !');
            })
            .catch(error=>{
            if(error && error.response){
                setTimeout(()=>{
                    dispatch(actionCreators.handleBackendError(error.response));
                }, 500);
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};



export const setRoles =(data)=>{
    return {
        type:actionTypes.SET_ROLES,
        roles:data
    };
};


export const rolesLoading =(data)=>{
    return {
        type:actionTypes.ROLES_LOADING,
        rolesLoading: data,
    };
};

export const getRoles =(hid)=>{
    return dispatch=> {
        dispatch(rolesLoading(true));
        axios.get(`${backendURL}/hospital/${hid}/settings/roles`,backendConfig)
            .then( resp  => {
                dispatch(setRoles(resp.data.payload));
                dispatch(rolesLoading(false));

            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(rolesLoading(false));
        });
    };
};

export const sendRolesLayout =(layout,hid)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/hospital/${hid}/settings/roles`,
            layout,
            backendConfig).then(resp=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(setRoles(resp.data));
             })
            .catch(error=>{
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                if(error && error.response){
                    dispatch(actionCreators.handleBackendError(error.response));
                }else{
                    if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
                }
            });
    };
};

export const changeRolesPosition =(layout,hid)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/hospital/${hid}/settings/roleposition`,
            layout,
            backendConfig).then(resp=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            dispatch(actionCreators.showSaveBtnAndResetMsg(false));
            dispatch(setRoles(resp.data));
        })
            .catch(error=>{
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                if(error && error.response){
                    dispatch(actionCreators.handleBackendError(error.response));
                }else{
                    if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
                }
            });
    };
};


export const setUsersRoles =(data)=>{
    return {
        type:actionTypes.SET_RESU_ROLES,
        usersRoles:data
    };
};

export const getUsersRoles =(hospitalId)=>{
    return dispatch=> {
        axios.get(`${backendURL}/hospital/${hospitalId}/settings/users`,backendConfig)
            .then( resp  => {
                dispatch(setUsersRoles(resp.data.payload));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const onUsersRolesModalToggle =(selected)=>{
    return {
        type:actionTypes.TOGGLE_RESU_ROLES_MODAL,
        usersRolesModalToggle:{
            selected:selected
        }
    };
};

export const setUserInfo =(data)=>{
    return {
        type:actionTypes.SET_RESU_INFO,
        userInfo:data
    };
};

export const getUserInfo =(userId,currentUserId)=>{
    return dispatch=> {
        axios.get(`${backendURL}/user/${userId}/account`,backendConfig)
            .then( resp  => {
                dispatch(setUserInfo(resp.data.payload));
                if(currentUserId===Number(userId)){
                    i18n.changeLanguage(resp.data.payload.person.language
                        && resp.data.payload.person.language.value ?resp.data.payload.person.language.value:navigator.language );
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


export const changePerm =(data)=>{
    return {
        type:actionTypes.CHANGE_PERM,
        value:data
    };
};

export const addNewUser =(data)=>{
    return {
        type:actionTypes.ADD_NEW_RESU,
        value:data
    };
};

export const newUserId =(data)=>{
    return {
        type:actionTypes.NEW_RESU_ID,
        value:data
    };
};

export const selfDeleted =(data)=>{
    return {
        type:actionTypes.SELF_DELETED,
        value:data
    };
};


export const deleteUser =(userId,hid,msg)=>{
    return dispatch=> {
        axios.delete(`${backendURL}/user/${userId?userId:''}`,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.setGeneralSaveMsg(resp.data.payload,false));
                dispatch(actionCreators.setUserInfo({}));                
                dispatch(actionCreators.resetPageUserInfo({}));

                if(resp.data.payload==="You must log back in."){
                   dispatch(actionCreators.selfDeleted(true));
                    dispatch(actionCreators.setGeneralSaveMsg(msg,false));
                    const timeoutMsg=setTimeout(function(){
                        dispatch(actionCreators.setGeneralSaveMsg("",false));
                        dispatch(actionCreators.selfDeleted(false));
                        // sessionStorage.removeItem('user');
                        // sessionStorage.removeItem('credentials');
                        dispatch(actionCreators.logout());
                        dispatch(actionCreators.logoutBackEnd());
                        dispatch(actionCreators.timedOut(true));
                        dispatch(actionCreators.errorLogin(null));
                        dispatch(actionCreators.login(false, null));
                        dispatch(actionCreators.redirectToHomePage('/',''));
                    }, 5000);
                    return () => clearTimeout(timeoutMsg);
                }

                if(Number(hid)>=0){
                    dispatch(actionCreators.getUsersRoles(hid));
                }

                //reset account
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


//todo change the path here for the BPF export

export const getBpfExportStatus =(hid)=>{
    return dispatch=>{
        dispatch(actionCreators.loadingBpfExport(true,false));
        axios.get(`${backendURL}/hospital/${hid}/export/bpf/status`,backendConfig)
            .then(resp=>{
                if(resp.data.payload.processing){
                    dispatch(actionCreators.loadingBpfExport(true,false));
                }else{
                    if(resp.data.payload.fileSize>=0 && resp.data.payload.exportFilePath) dispatch(actionCreators.bpfExportPath({
                        exportFilePath: resp.data.payload.exportFilePath,
                        fileSize:resp.data.payload.fileSize
                    }));
                    dispatch(actionCreators.loadingBpfExport(false,true));

                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingBpfExport(false,false));

        });
    };
};

export const prepareBpfExport =(hid,timezone)=>{

    return dispatch=>{
        dispatch(actionCreators.loadingBpfExport(true,false));
        axios.post(`${backendURL}/hospital/${hid}/export/bpf/start?timezone=${encodeURIComponent(timezone)}`,'',backendConfig)
            .then(resp=>{
                //dispatch(actionCreators.loadingSearchPdf(false,true));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.loadingBpfExport(false,false));

        });
    };
};

export const loadingBpfExport=(loading,ready)=>{
    return {
        type: actionTypes.LOADING_BPF_EXPORT,
        loadingBpfExport:{
            loading,
            ready
        }
    };
};

export const downloadBpfExport =(path)=>{
    return dispatch=> {
        window.location.href = path;
    };
};

export const bpfExportPath=(bpfExportPath)=>{
    return {
        type: actionTypes.BPF_EXPORT_PATH,
        bpfExportPath
    };
};




