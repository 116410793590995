import React, {Suspense, lazy, useEffect} from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import Loader from "./Loader/Loader";
import loadable from "@loadable/component";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Sass/BasicCorporate.scss";
import "./Components/Corporate/assets/css/styles.css";
import "./Sass/Layout.scss";
import BasicCorporatePage from "./Components/BasicCorporate/BasicCorporatePage";
import {getFile} from "./getFile/getFile";
import process from "process";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {useCheck} from "./Hooks/onDefaultValueCheck/onDefaultValueCheck";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as actionCreators from "./Store/Actions";


const Lodash = loadable.lib(() => import('lodash' /* webpackChunkName: "lodash" */));
//const Moment = loadable.lib(() => import('moment' /* webpackChunkName: "moment" */));
const SetupWizard = lazy(() => import('./Components/SetupWizard/SetupWizard'/* webpackChunkName: "SetupWizard" */));
const AppPage = lazy(() => import("./Components/App/AppPage" /* webpackChunkName: "appHere" */));

Lodash.load();
//Moment.load();


function App() {
    const [check]= useCheck();
    const settingsData = useSelector(state=>check(state,'contextHospital.settings',[]));
    const dispatch = useDispatch();
    const logged = useSelector(state=> check(state,'login.logged',false),shallowEqual);
    const loggedLoader = useSelector(state=> check(state,'login.loading',true),shallowEqual);
    const pathName = useLocation().pathname;

    const getIndex=(slugName)=>{
        for(let i=0;i<settingsData.length;i++){
            if(settingsData[i].slug === slugName) return i;
        }
        return false;
    };
    const sentry=()=>{
        if(getIndex('reporting.allowed')===false) return false;
        else return settingsData[getIndex('reporting.allowed')].value==="true";
    };

    if (process.env.REACT_APP_ENV !== "dev" && sentry()){
        Sentry.init({
            dsn: "https://94a0f192e8e946d38c2d6147a84b8c37@sentry.dev.bonemonitor.com/3",
            integrations: [new BrowserTracing()],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            release: process.env.REACT_APP_VERSION,
            environment: process.env.REACT_APP_ENV,
            initialScope: {
                tags: {
                    "version": process.env.REACT_APP_VERSION_NUMBER,
                    "branch": process.env.REACT_APP_BRANCH,
                    "commit": process.env.REACT_APP_COMMIT_SHORT_SHA,
                    "brand": process.env.REACT_APP_BRAND,
                },
            },
        });
    }


    useEffect(()=>{
            getFile('/locales/kpiRef.properties');
        },[]);

    useEffect(()=>{
        dispatch(actionCreators.userChange(null, null,false,false));

    },[]);

  return (
        <Routes>
           <Route path="/" element={<Suspense fallback={<Loader/>}>
                <BasicCorporatePage/>
            </Suspense>}/>
            <Route path='/app/*'
                   element={<Suspense fallback={<Loader/>}>
                       {logged
                           ?<AppPage/>
                           :loggedLoader
                               ?<Loader/>
                               :logged
                                   ?<AppPage/>
                                   :<Navigate to={
                           {
                               pathname: '/',
                               state: {
                                   from: pathName
                               }
                           }
                       }
                       />}
                   </Suspense>}/>
            <Route path='/setup/*'
                   element={<Suspense fallback={<Loader/>}>
                       <SetupWizard/>
                   </Suspense>} />
        </Routes>
  );
}

export default App;
