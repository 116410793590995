import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useSelector} from "react-redux";
import {useCheck} from "../../../../Hooks/onDefaultValueCheck/onDefaultValueCheck";

const CustomOverlay=(props)=>{
    const [check]=useCheck();
    const blindMode = useSelector(state=>check(state,'login.userInfo.blindMode',"NORMAL"));

    return  <OverlayTrigger placement={props.placement}
                            delay={props.delay}
                            overlay={<Tooltip id={props.tooltipId}
                                              data-blind={blindMode}
                            >{props.tooltipText}
                            </Tooltip>}>
        {props.children}
    </OverlayTrigger>;
};

export default CustomOverlay ;