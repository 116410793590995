export const LOGIN = 'LOGIN';
export const RESU_CHANGE = 'RESU_CHANGE';
export const HOSPITAL = 'HOSPITAL';
export const PAGE_METADATA = 'PAGE_METADATA';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const SET_TASK = 'SET_TASK';
export const SET_TASK_BY_PATIENT = 'SET_TASK_BY_PATIENT';
export const ORDER_BY_PATIENT = 'ORDER_BY_PATIENT';
export const FILTER_TASKS = 'FILTER_TASKS';
export const FILTER_TASKS_PATIENTS = 'FILTER_TASKS_PATIENTS';
export const ORDER_TASKS = 'ORDER_TASKS';
export const ORDER_TASKS_PATIENT = 'ORDER_TASKS_PATIENT';
export const ADD_PATIENT_MODE = 'ADD_PATIENT_MODE';
export const ERROR_FIELD = 'ERROR_FIELD';
export const ERROR = 'ERROR';
export const RESU_LOGOUT = 'RESU_LOGOUT';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SHOW_SAVE_BTN = 'SHOW_SAVE_BTN';
export const SET_PATIENT = 'SET_PATIENT';
export const ADD_PATIENT = 'ADD_PATIENT';
export const ERROR_PATIENT = 'ERROR_PATIENT';
export const PATIENT_CHANGE = 'PATIENT_CHANGE';
export const PATIENT_GRID = 'PATIENT_GRID';
export const SEARCH_PATIENT_INPUT = 'SEARCH_PATIENT_INPUT';
export const RESET_PATIENT_MODE = 'RESET_PATIENT_MODE';
export const RESET_ERROR_FIELD = 'RESET_ERROR_FIELD';
export const EDIT_LETTER = 'EDIT_LETTER';
export const TOGGLE_LETTER_MODAL = 'TOGGLE_LETTER_MODAL';
export const ON_CHANGE_LETTER = 'ON_CHANGE_LETTER';
export const CREATE_LETTER = 'CREATE_LETTER';
export const UPDATE_ALL_LETTERS = 'UPDATE_ALL_LETTERS';
export const RESET_SAVE_MSG = 'RESET_SAVE_MSG';
export const SET_CURSOR = 'SET_CURSOR';
export const MADE_A_CHANGE = 'MADE_A_CHANGE';
export const TOGGLE_LETTER = 'TOGGLE_LETTER';
export const CHANGE_MONITORING = 'CHANGE_MONITORING';
export const RESET_CHANGE_MONITORING = 'RESET_CHANGE_MONITORING';
export const OPEN_LETTER = 'OPEN_LETTER';
export const SAVE_PATIENT = 'SAVE_PATIENT';
export const TIMED_OUT = 'TIMED_OUT';
export const ERROR_LOGIN = 'ERROR_LOGIN';
export const UPDATE_KPI = 'UPDATE_KPI';
export const RESET_TOGGLE_LETTER = 'RESET_TOGGLE_LETTER';
export const ERROR_FIELD_BE = 'ERROR_FIELD_BE';
export const NOT_FOUND = 'NOT_FOUND';
export const IS_LOADING = 'IS_LOADING';
export const HOSPITAL_LOADING = 'HOSPITAL_LOADING';
export const DWORDSSAP_CHANGE = 'DWORDSSAP_CHANGE';
export const DWORDSSAP_CHANGE_RESET = 'DWORDSSAP_CHANGE_RESET';
export const SET_ROLES = 'SET_ROLES';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';
export const RESU_MADE_CHANGE = 'RESU_MADE_CHANGE';
export const ON_CHANGE_MANAGEMENT = 'ON_CHANGE_MANAGEMENT';
export const SHOW_SAVE_MANAGEMENT_BTN = 'SHOW_SAVE_MANAGEMENT_BTN';
export const MANAGEMENT_ERROR = 'MANAGEMENT_ERROR';
export const RESET_MANAGEMENT_ERROR = 'RESET_MANAGEMENT_ERROR';
export const MADE_A_CHANGE_HOSPITAL = 'MADE_A_CHANGE_HOSPITAL';
export const KPI_LOADING = 'KPI_LOADING';
export const ROLES_LOADING = 'ROLES_LOADING';
export const PERMISSIONS_LOADING = 'PERMISSIONS_LOADING';
export const SET_RESU_ROLES = 'SET_RESU_ROLES';
export const TOGGLE_RESU_ROLES_MODAL = 'TOGGLE_RESU_ROLES_MODAL';
export const SET_RESU_INFO = 'SET_RESU_INFO';
export const ON_CHANGE_MANAGEMENT_OTHER = 'ON_CHANGE_MANAGEMENT_OTHER';
export const ON_CHANGE_INFO_OTHER = 'ON_CHANGE_INFO_OTHER';
export const CHANGE_PERM = 'CHANGE_PERM';
export const ADD_NEW_RESU = 'ADD_NEW_RESU';
export const RESET_FIELD = 'RESET_FIELD';
export const ON_RESET_PAGE_RESU_INFO = 'ON_RESET_PAGE_RESU_INFO';
export const RESU_PATIENT_ID = 'RESU_PATIENT_ID';
export const NEW_RESU_ID = 'NEW_RESU_ID';
export const GENERAL_SAVE_MSG = 'GENERAL_SAVE_MSG';
export const PATIENT_DELETED = 'PATIENT_DELETED';
export const SERVER_MSG = 'SERVER_MSG';
export const MAINTENANCE_CHANGE = 'MAINTENANCE_CHANGE';
export const SET_IMPORTING = 'SET_IMPORTING';
export const RESET_MAINTENANCE = 'RESET_MAINTENANCE';
export const SET_MAINTENANCE_REPORT = 'SET_MAINTENANCE_REPORT';
export const PATIENT_GRID_LOADING = 'PATIENT_GRID_LOADING';
export const REDIRECT_HOME = 'REDIRECT_HOME';
export const SET_UPLOAD_CSV = 'SET_UPLOAD_CSV';
export const IMPORT_BACKEND_ERROR = 'IMPORT_BACKEND_ERROR';
export const HOSPITAL_DETAILS_CHANGE = 'HOSPITAL_DETAILS_CHANGE';
export const UPDATE_HOSPITAL_DETAILS = 'UPDATE_HOSPITAL_DETAILS';
export const SET_MACHINES = 'SET_MACHINES';
export const SET_EXPORTING_PATIENT = 'SET_EXPORTING_PATIENT';
export const NO_TO_ALL_FALL = 'NO_TO_ALL_FALL';
export const SETTINGS = 'SETTINGS';
export const SET_SETTINGS = 'SET_SETTINGS';
export const NO_TO_ALL_MONITORING = 'NO_TO_ALL_MONITORING';
export const SELF_DELETED = 'SELF_DELETED';
export const ON_CHANGE_RESU_INFO = 'ON_CHANGE_RESU_INFO';
export const SET_EXPORTING_STATUS_DATA = 'SET_EXPORTING_STATUS_DATA';
export const SET_EXPORT_ERROR_MSG = 'SET_EXPORT_ERROR_MSG';
export const SET_ANTIOSTEO_MEDICATION = 'SET_ANTIOSTEO_MEDICATION';
export const SET_ANTIOSTEO_MEDICATION_SELECTED = 'SET_ANTIOSTEO_MEDICATION_SELECTED';
export const ADD_LABTEST = 'ADD_LABTEST';
export const UPDATE_LABTEST = 'UPDATE_LABTEST';
export const DELETE_LABTEST = 'DELETE_LABTEST';
export const TOGGLE_LETTER_TEMPLATE_GROUP = 'TOGGLE_LETTER_TEMPLATE_GROUP';
export const TOGGLE_LETTER_TEMPLATE_MODAL = 'TOGGLE_LETTER_TEMPLATE_MODAL';
export const CREATE_LETTER_TEMPLATE = 'CREATE_LETTER_TEMPLATE';
export const TOGGLE_MIDDLE_LETTER_TEMPLATE_MODAL = 'TOGGLE_MIDDLE_LETTER_TEMPLATE_MODAL';
export const ON_CHANGE_LETTER_TEMPLATE = 'ON_CHANGE_LETTER_TEMPLATE';
export const SET_KPI_TEMP_DATE_RANGE = 'SET_KPI_TEMP_DATE_RANGE';
export const ALL_LETTER_LOADING = 'ALL_LETTER_LOADING';
export const DELETE_MONITORING_DRUGS = 'DELETE_MONITORING_DRUGS';
export const ADD_MONITORING_DRUGS = 'ADD_MONITORING_DRUGS';
export const SET_MASTER_DRUGS = 'SET_MASTER_DRUGS';
export const ON_SAVE_LETTER_TEMPLATE = 'ON_SAVE_LETTER_TEMPLATE';
export const DONT_KNOW_TO_ALL_LABTEST = 'DONT_KNOW_TO_ALL_LABTEST';
export const SET_ALL_LETTER_TEMPLATE = 'SET_ALL_LETTER_TEMPLATE';
export const SET_SINGLE_LETTER_TEMPLATE = 'SET_SINGLE_LETTER_TEMPLATE';
export const ON_DELETE_LETTER_TEMPLATE = 'ON_DELETE_LETTER_TEMPLATE';
export const ON_DELETE_LETTER = 'ON_DELETE_LETTER';
export const SET_SELECTED_FILE = 'SET_SELECTED_FILE';
export const SET_SELECTED_FILE_IMPORT = 'SET_SELECTED_FILE_IMPORT';
export const GENERAL_TOGGLE_MODAL = 'GENERAL_TOGGLE_MODAL';
export const CHANGE_DRUGS = 'CHANGE_DRUGS';
export const SET_MACHINES_MASTER = 'SET_MACHINES_MASTER';
export const DELETE_IN_LOGIN = 'DELETE_IN_LOGIN';
export const UPDATE_IN_LOGIN = 'UPDATE_IN_LOGIN';
export const ADD_IN_LOGIN = 'ADD_IN_LOGIN';
export const SET_PATIENT_COUNT = 'SET_PATIENT_COUNT';
export const DELETE_IN_PATIENT = 'DELETE_IN_PATIENT';
export const UPDATE_IN_PATIENT = 'UPDATE_IN_PATIENT';
export const ADD_IN_PATIENT = 'ADD_IN_PATIENT';
export const DELETE_IN_HOSPITAL = 'DELETE_IN_HOSPITAL';
export const UPDATE_IN_HOSPITAL = 'UPDATE_IN_HOSPITAL';
export const ADD_IN_HOSPITAL = 'ADD_IN_HOSPITAL';
export const SET_TEMP_LETTER_TEMPLATE = 'SET_TEMP_LETTER_TEMPLATE';
export const RESET_PATIENT = 'RESET_PATIENT';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const TOGGLE_MONITORING_DELAYS_MODAL = 'TOGGLE_MONITORING_DELAYS_MODAL';
export const DELETE_MONITORING_DELAYS = 'DELETE_MONITORING_DELAYS';
export const UPDATE_MONITORING_DELAYS = 'UPDATE_MONITORING_DELAYS';
export const ADD_MONITORING_DELAYS = 'ADD_MONITORING_DELAYS';
export const DEFAULT_ARR_MONI = 'DEFAULT_ARR_MONI';
export const SHOW_MONI_LIST = 'SHOW_MONI_LIST';
export const ADD_PLANNED_MONITORING = 'ADD_PLANNED_MONITORING';
export const ON_CHANGE_LANG = 'ON_CHANGE_LANG';
export const SET_MODE = 'SET_MODE';
export const SET_HOSPITAL_DATA = 'SET_HOSPITAL_DATA';
export const SET_BLIND_MODE = 'SET_BLIND_MODE';
export const SET_TASKS_COUNT = 'SET_TASKS_COUNT';
export const CREATE_CA = 'CREATE_CA';
export const UPDATE_CA = 'UPDATE_CA';
export const DELETE_CA = 'DELETE_CA';
export const RESET_CLINICAL = 'RESET_CLINICAL';
export const SET_PDF_SIGNATURE = 'SET_PDF_SIGNATURE';
export const OPEN_ALL_SEARCH_SECTION = 'OPEN_ALL_SEARCH_SECTION';
export const CLOSE_ALL_SEARCH_SECTION = 'CLOSE_ALL_SEARCH_SECTION';
export const TOGGLE_SEARCH_SECTION = 'TOGGLE_SEARCH_SECTION';
export const SEARCH_CHANGE = 'SEARCH_CHANGE';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_SELECTED_SAVE_SEARCH_MODAL = 'SET_SELECTED_SAVE_SEARCH_MODAL';
export const TOGGLE_SAVE_SEARCH_MODAL = 'TOGGLE_SAVE_SEARCH_MODAL';
export const DELETE_IN_SEARCH = 'DELETE_IN_SEARCH';
export const UPDATE_IN_SEARCH = 'UPDATE_IN_SEARCH';
export const ADD_IN_SEARCH = 'ADD_IN_SEARCH';
export const SET_SEARCHDATA = 'SET_SEARCHDATA';
export const SET_SEARCHDATALIST = 'SET_SEARCHDATALIST';
export const SET_SEARCHRESULT = 'SET_SEARCHRESULT';
export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT';
export const LOADING_SEARCH_CSV = 'LOADING_SEARCH_CSV';
export const LOADING_SEARCH_PDF = 'LOADING_SEARCH_PDF';
export const MADE_A_CHANGE_SEARCH = 'MADE_A_CHANGE_SEARCH';
export const SAVED = 'SAVED';
export const SEARCH_RESULT_LOADING = 'SEARCH_RESULT_LOADING';
export const YES_TO_ALL_OUTPUT = 'YES_TO_ALL_OUTPUT';
export const LOADING_DASHBOARD_TASK_CSV = 'LOADING_DASHBOARD_TASK_CSV';
export const LOADING_DASHBOARD_PATIENT_CSV = 'LOADING_DASHBOARD_PATIENT_CSV';
export const LOADING_DASHBOARD_TASK_PDF = 'LOADING_DASHBOARD_TASK_PDF';
export const LOADING_DASHBOARD_PATIENT_PDF = 'LOADING_DASHBOARD_PATIENT_PDF';
export const SET_ALL_POSSIBLE_MONITORINGS = 'SET_ALL_POSSIBLE_MONITORINGS';
export const LOADING_BPF_EXPORT = 'LOADING_BPF_EXPORT';
export const DATE_FORMAT = 'DATE_FORMAT';
export const UPDATE_PROFILE_ITEMS = 'UPDATE_PROFILE_ITEMS';
export const ADD_PROFILE_ITEMS = 'ADD_PROFILE_ITEMS';
export const SHOW_SAVE_LOADER = 'SHOW_SAVE_LOADER';
export const BPF_EXPORT_PATH = 'BPF_EXPORT_PATH';
export const PATH_DASHBOARD_TASK_CSV = 'PATH_DASHBOARD_TASK_CSV';
export const PATH_DASHBOARD_PATIENT_CSV = 'PATH_DASHBOARD_PATIENT_CSV';
export const PATH_DASHBOARD_PATIENT_PDF = 'PATH_DASHBOARD_PATIENT_PDF';
export const PATH_DASHBOARD_TASK_PDF = 'PATH_DASHBOARD_TASK_PDF';
export const PATH_PDF_STATUS = 'PATH_PDF_STATUS';
export const PATH_CSV_STATUS = 'PATH_CSV_STATUS';
export const RESET_SEARCH_BY = 'RESET_SEARCH_BY';
export const RESET_SEARCH_OUTPUT = 'RESET_SEARCH_OUTPUT';
export const DIRTY_TRANSLATION = 'DIRTY_TRANSLATION';
export const RESET_DIRTY_TRANSLATION = 'RESET_DIRTY_TRANSLATION';
export const SET_ALL_FILE = 'SET_ALL_FILE';
export const HOSPITAL_DETAILS_ONCHANGE = 'HOSPITAL_DETAILS_ONCHANGE';
export const SET_SEARCH_OUTPUT = 'SET_SEARCH_OUTPUT';
export const SET_OUTPUT = 'SET_OUTPUT';
export const SET_SEARCH_OUTPUTTEMP = 'SET_SEARCH_OUTPUTTEMP';
export const TOGGLE_GENERAL_UI = 'TOGGLE_GENERAL_UI';
export const TOGGLE_GENERAL_ERROR = 'TOGGLE_GENERAL_ERROR';
export const HOSPITAL_PROFILE_LOADING = 'HOSPITAL_PROFILE_LOADING';
export const SET_HOSPITAL_PROFILE = 'SET_HOSPITAL_PROFILE';
export const SET_PROFILE_ARRAY = 'SET_PROFILE_ARRAY';
export const IS_GENERAL_LOADING = 'IS_GENERAL_LOADING';
export const TO_DELETE_TRANSLATION = 'TO_DELETE_TRANSLATION';
export const TO_SET_DELETE_TRANSLATION = 'TO_SET_DELETE_TRANSLATION';
export const NEW_PATIENT = 'NEW_PATIENT';
export const LOGGIN_LOADER = 'LOGGIN_LOADER';
export const MUST_REFRESH = 'MUST_REFRESH';
export const SELECTED_KPI = 'SELECTED_KPI';



