import * as actionTypes from '../Actions/actionTypes';
import {ChangePathName} from "../../Hooks/ChangePathName/ChangePathName";
const dotProp = require('dot-prop-immutable');

const initialState = {};


const contextUserReducer = ( state = initialState, action ) => {
    let a,b;
    switch ( action.type ) {

        case actionTypes.SELF_DELETED:
            return dotProp.set(state, 'selfDeleted', action.value);

        case actionTypes.ADD_NEW_RESU:
            return dotProp.set(state, 'addNewUser', action.value);

        case actionTypes.NEW_RESU_ID:
            return dotProp.set(state, 'newUserId', action.value);

        case actionTypes.CHANGE_PERM:
            return dotProp.set(state, 'permChange', action.value);

        case actionTypes.MANAGEMENT_ERROR:
            return dotProp.set(state, 'userManagementError', action.value);

        case actionTypes.RESET_MANAGEMENT_ERROR:
            return dotProp.set(state, 'userManagementError', {});

        case actionTypes.SHOW_SAVE_MANAGEMENT_BTN:
            return dotProp.set(state, 'showManageSaveBtn', action.value);

        case actionTypes.ON_CHANGE_MANAGEMENT:
            return dotProp.set(state, ChangePathName(action.e.name.name), action.e.value);

        case actionTypes.ON_CHANGE_MANAGEMENT_OTHER:
            a=dotProp.set(state, "pageUserInfo.id", action.e.name.id);
            return dotProp.set(a, ChangePathName(action.e.name.name), action.e.value);

        case actionTypes.ON_RESET_PAGE_RESU_INFO:
            return dotProp.set(state, 'pageUserInfo', false);

        case actionTypes.ON_CHANGE_INFO_OTHER:
            b=dotProp.set(state, "userInfo.id", action.e.name.id);
            b=dotProp.set(b, "userInfo.person.id", action.e.name.id);
            return dotProp.set(b, ChangePathName(action.e.name.name), action.e.value);

        case actionTypes.RESU_MADE_CHANGE:
            return dotProp.set(state, 'userMadeAChange', action.userMadeAChange);

        case actionTypes.DWORDSSAP_CHANGE:
            return dotProp.set(state, 'passwordChangedMsg', {...action.passwordChangedMsg});

        case actionTypes.DWORDSSAP_CHANGE_RESET:
            return dotProp.set(state, 'passwordChangedMsg', {
                passwordChangedMsg:null,
                error:false
            });

        case actionTypes.SET_RESU_INFO:
            return dotProp.set(state, 'userInfo', action.userInfo);

        case actionTypes.ON_CHANGE_RESU_INFO:
            return dotProp.set(state, action.e.name, action.e.value);

        default:
            return state;
    }
};

export default contextUserReducer;