import * as actionTypes from './actionTypes';
import * as actionCreators from './index';


export const dontKnowToAllTest =(tests)=>{
    return dispatch=>{
        dispatch(actionCreators.resetSaveMsg());
        dispatch(actionCreators.setGeneralSaveMsg("",false));
        dispatch(actionCreators.showSaveBtn(true));
        return dispatch(onDontKnowToAllTest(tests));
    };
};

export const onDontKnowToAllTest =(tests)=>{
    return {
        type:actionTypes.DONT_KNOW_TO_ALL_LABTEST,
        tests
    };
};



