import * as actionTypes from './actionTypes';


export const noToAllFall =()=>{
    return {
        type:actionTypes.NO_TO_ALL_FALL,
        fallInter: {
            referralFallsService: 'NO',
            strengthBalance: 'NO',
            visionAssessment: 'NO',
            homeOccupationTherapy: 'NO',
            medicationReview: 'NO'
        }
    };
};
