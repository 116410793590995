import * as actionTypes from "./actionTypes";
import axios from 'axios';
import * as actionCreators from './index';
import {backendURL, backendConfig} from "../utility";


export const setSingleLetterTemplate =(singleLetterTemplate,groupIndex,templateIndex)=>{
    return {
        type:actionTypes.SET_SINGLE_LETTER_TEMPLATE,
        singleLetterTemplate,
        groupIndex,
        templateIndex
    };
};


export const getSingleLetterTemplate =(letterTemplateId,groupIndex,templateIndex)=>{
    return dispatch=>{
        axios.get(`${backendURL}/lettertemplate/${letterTemplateId}`,backendConfig )
            .then( resp  => {
                dispatch(actionCreators.setSingleLetterTemplate(resp.data.payload,groupIndex,templateIndex));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


export const getAllLetterTemplate =()=>{
    return dispatch=>{
        axios.get(`${backendURL}/lettertemplate/all`,backendConfig )
            .then( resp  => {
                dispatch(actionCreators.setAllLetterTemplate(resp.data.payload));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const setAllLetterTemplate =(allLetterTemplate)=>{
    return {
        type:actionTypes.SET_ALL_LETTER_TEMPLATE,
        allLetterTemplate
    };
};

export const onSetSaveLetterTemplate =(groupIndex,templateIndex,data)=>{
    return {
        type:actionTypes.ON_SAVE_LETTER_TEMPLATE,
        groupIndex,
        templateIndex,
        data
    };
};

export const onSaveLetterTemplate =(groupIndex,templateIndex,data)=>{
    return dispatch =>{
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/lettertemplate/update`,data,backendConfig )
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.resetErrorField());
                dispatch(actionCreators.madeAchange(false));
                dispatch(actionCreators.userMadeAChange(false));
                dispatch(actionCreators.madeAChangeHospital(false));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(onSetSaveLetterTemplate(groupIndex,templateIndex,resp.data.payload));
                dispatch(actionCreators.getAllLetterTemplate());
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const onChangeLetterTemplate =(groupIndex,templateIndex,data,pathName)=>{
    return {
        type:actionTypes.ON_CHANGE_LETTER_TEMPLATE,
        groupIndex,
        templateIndex,
        data,
        pathName
    };
};



export const onDeleteLetterTemplate =(templateGroupIndex,templateIndex)=>{
    return {
        type:actionTypes.ON_DELETE_LETTER_TEMPLATE,
        templateGroupIndex,
        templateIndex
        };
    };

export const sendAllLetterTemplate =(letterTemplate)=>{
    return dispatch=>{
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/lettertemplate/all`,letterTemplate,backendConfig )
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.resetErrorField());
                dispatch(actionCreators.madeAchange(false));
                dispatch(actionCreators.userMadeAChange(false));
                dispatch(actionCreators.madeAChangeHospital(false));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(actionCreators.setAllLetterTemplate(resp.data.payload));
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.hospitalUpdated",false));
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });

    };};

export const onDeleteLetterTemplatePost =(letterTemplateData)=>{
    return dispatch=>{
        axios.delete(`${backendURL}/lettertemplate/${letterTemplateData.id}`,backendConfig ) //${}
            .then( resp  => {
                dispatch(actionCreators.getAllLetterTemplate());
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };};


export const createNewLetterTemplate =(i,value)=>{
    return {
        type:actionTypes.CREATE_LETTER_TEMPLATE,
        index:i,
        value:value
    };
};


export const openLetterTempAfterCreate =(i,value)=>{
    return (dispatch,getState)=>{
        axios.post(`${backendURL}/lettertemplate/create`,value,backendConfig )
            .then( resp  => {
                getState().contextHospital.generalSettings.templateGroup[i].templates===null
                    ?dispatch(createNewLetterTemplate(i,[resp.data.payload]))
                    :dispatch(createNewLetterTemplate(i,resp.data.payload));
                dispatch(onToggleModalLetterTemplate(i,getState().contextHospital.generalSettings.templateGroup[i].templates.length-1,true));
                dispatch(actionCreators.setTempLetterTemplate(getState().contextHospital.generalSettings.templateGroup[i].templates[getState().contextHospital.generalSettings.templateGroup[i].templates.length-1]));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


/*TODO
* KEEP THAT : HOW TO MAKE ASYNC DISPATCH
* --------------------------------------
* const Test=(i,value,dispatch)=>new Promise((res,rej)=>{
    dispatch(createNewLetterTemplate(i,value))
    res();
})

export const openLetterTempAfterCreate =(i,value)=>{
    return (dispatch,getState)=>{
        //post here
        Test(i,value,dispatch).then(res=>{
            //then open modal when the letter templae is saved
            dispatch(onToggleModalLetterTemplate(i,getState().contextHospital.generalSettings.templateGroup[i].lettersTemplates.length-1,true))
        })
    }
};
*
*
* */

export const setTempLetterTemplate =(value)=>{
    return {
        type:actionTypes.SET_TEMP_LETTER_TEMPLATE,
        value
    };
};

export const onToggleModalLetterTemplate =(groupIndex,templateIndex,open)=>{
    return {
        type:actionTypes.TOGGLE_LETTER_TEMPLATE_MODAL,
        groupIndex,
        templateIndex,
        open
    };
};
export const onToggleModalMiddleLetterTemplate =(name,selected,open)=>{
    return {
        type:actionTypes.TOGGLE_MIDDLE_LETTER_TEMPLATE_MODAL,
        open:open,
        name:name,
        selected:selected
    };
};

export const toggleLetterTemplateGroup =(id,open)=>{
    return {
        type:actionTypes.TOGGLE_LETTER_TEMPLATE_GROUP,
        value:{
            id:id,
            open:open
        },
    };
};