import axios from "axios";
import * as actionCreators from "./index";
import * as actionTypes from "./actionTypes";
import {backendURL, backendConfig} from "../utility";

export const redirectToHomePage=(val,msg)=>{
    return {
        type: actionTypes.REDIRECT_HOME,
        redirectToHomePage: val,
        msg:msg
    };
};

export const setFirstPassword =(data)=>{
    return dispatch=> {
        axios.post(`${backendURL}/user/initAdminPassword`,
            data
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.userChange("admin",data.newPassword,true));
                dispatch(redirectToHomePage('/setup/hospital-details',''));
            }).catch(error=>{
            if(error && error.response){
                dispatch(redirectToHomePage(false,error.response
                    && error.response.data
                        ?error.response.data.error.details
                        :"Error"
                ));
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const allowToSeeSetup =()=>{
    return dispatch=> {
        axios.get(`${backendURL}/user/initAdminPassword`,backendConfig)
            .then( resp  => {
                if(resp.data.payload){
                    dispatch(redirectToHomePage(false,''));
                }else{
                    dispatch(redirectToHomePage('/','Password already set'));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};