import * as actionTypes from '../Actions/actionTypes';
import {ChangePathName} from "../../Hooks/ChangePathName/ChangePathName";
const dotProp = require('dot-prop-immutable');


const initialState = {};


const changeNullValue=(name,state,isArr)=>{
    let updatedName=ChangePathName(name);
    let arr=updatedName.split('.');
    let newName=arr[0];
    let newState=state;
    for (var i=1;i<arr.length;i++){
        newName=newName+'.'+arr[i];
        if(dotProp.get(state, newName)===undefined || dotProp.get(state, newName)===null){
            if(Number(arr[i+1])>=0){
                newState=dotProp.set(newState, newName, [{}]);
            }else{
                if(isArr && i===arr.length-1 ){
                    newState=dotProp.set(newState, newName, []);
                }else{
                    newState=dotProp.set(newState, newName, {});
                }

            }
        }
    }
    return newState;
};

const extendedSearchReducer = ( state = initialState, action ) => {
let a,b,c,d,e;
    switch ( action.type ) {
        //CRUD OPERATION
        case actionTypes.DELETE_IN_SEARCH:
            return dotProp.delete(changeNullValue(action.name,state,action.isArr), ChangePathName( `${action.name}.${action.elemIndex}`));
        case actionTypes.UPDATE_IN_SEARCH:
            return dotProp.set(changeNullValue(action.name,state,action.isArr),ChangePathName( `${action.name}.${action.elemIndex}`),action.elem);
        case actionTypes.ADD_IN_SEARCH:
            return dotProp.merge(changeNullValue(action.name,state,action.isArr), ChangePathName( action.name), action.elem);
        case actionTypes.OPEN_ALL_SEARCH_SECTION:
            return dotProp.set(state,`toggleSection`,{});
        case actionTypes.CLOSE_ALL_SEARCH_SECTION:
            return dotProp.set(state,`toggleSection`,action.toggleSection);
        case actionTypes.TOGGLE_SEARCH_SECTION:
            return dotProp.toggle(state, action.name);
        case actionTypes.SEARCH_CHANGE:
            return dotProp.set(changeNullValue(action.e.name,state),ChangePathName( action.e.name),action.e.value);
        case actionTypes.RESET_SEARCH:
            a=dotProp.set(state, 'searchBy', []);
            b=dotProp.set(a, 'searchOutput', []);
            return dotProp.set(b, 'searchData', {});
        case actionTypes.SET_SEARCHDATA:
            c=dotProp.set(state, 'searchBy', action.searchBy?action.searchBy: []);
            d=dotProp.set(c, 'searchOutputTemp', action.searchOutput?action.searchOutput: []);
            return dotProp.set(d, 'searchData', action.searchData);
        case actionTypes.SET_SEARCHDATALIST:
            return dotProp.set(state, 'savedSearch', action.savedSearch);
        case actionTypes.SET_SEARCHRESULT:
            return dotProp.set(state, 'searchResult', action.searchResult);
        case actionTypes.RESET_SEARCH_RESULT:
            return dotProp.set(state, 'searchResult', {});
        case actionTypes.LOADING_SEARCH_CSV:
            return dotProp.set(state, 'loadingSearchCsv', action.loadingSearchCsv);
        case actionTypes.LOADING_SEARCH_PDF:
            return dotProp.set(state, 'loadingSearchPdf', action.loadingSearchPdf);
        case actionTypes.MADE_A_CHANGE_SEARCH:
            return dotProp.set(state, 'madeAchangeSearch', action.change);
        case actionTypes.SEARCH_RESULT_LOADING:
            return dotProp.set(state,`searchResultLoading`,action.searchResultLoading);
        case actionTypes.SET_ALL_POSSIBLE_MONITORINGS:
            return dotProp.set(state,`allPossibleMonitorings`,action.moni);
        case actionTypes.PATH_PDF_STATUS:
            return dotProp.set(state,`pathPdfStatus`,action.pathPdfStatus);
        case actionTypes.PATH_CSV_STATUS:
            return dotProp.set(state,`pathCsvStatus`,action.pathCsvStatus);
        case actionTypes.RESET_SEARCH_BY:
            a= dotProp.set(state, 'searchBy', []);
            return dotProp.set(a, 'searchData.criteria', {});
        case actionTypes.RESET_SEARCH_OUTPUT:
            b= dotProp.set(state, 'searchOutput', []);
            e= dotProp.set(b, 'searchOutputTemp', []);
            return dotProp.set(e, 'searchData.output', []);
        case actionTypes.SET_SEARCH_OUTPUT:
           return dotProp.set(state, 'searchOutput', [...action.data]);
        case actionTypes.SET_OUTPUT:
            return dotProp.set(state, 'searchData.output', [...action.data]);
        case actionTypes.SET_SEARCH_OUTPUTTEMP:
            return dotProp.set(state, 'searchOutputTemp', [...action.data]);
        default:
            return state;
    }
};

export default extendedSearchReducer;