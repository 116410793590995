import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actionCreators from "./index";
import {backendURL, backendConfig} from "../utility";
import * as Sentry from "@sentry/browser";


export const login = (data, msg) => {
    return {
        type: actionTypes.LOGIN,
        logged:data
    };
};
export const errorLogin = (msg) => {
    if(msg && msg.details){
        return {
            type: actionTypes.ERROR_LOGIN,
            msg:msg.message,
            msgDetails:msg.details
        };
    }else{
        return {
            type: actionTypes.ERROR_LOGIN,
            msg:msg
        };
    }
};

export const setPatientInformation = (data) => {
    return {
        type: actionTypes.RESU_PATIENT_ID,
        userPatientId:data
    };
};

export const logginLoader = (data) => {
    return {
        type: actionTypes.LOGGIN_LOADER,
        loading:data
    };
};

export const userChange = (username,psw,getHospitalAndData,showError=true) => {
    let credentials = backendConfig;
    if(username && psw){
        credentials.withCredentials= true;
        credentials.auth = {
            username: username,
            password: psw
        };
    }

    return dispatch=> {
        dispatch(actionCreators.logginLoader(true));
        axios.post(`${backendURL}/login`, {},credentials)
            .then( resp  => {
               /* Sentry.setTag("uuid", resp.data.payload.uuid
                    ?resp.data.payload.uuid
                    :"no uuid received");*/
                Sentry.setUser({ email: resp.data.payload && resp.data.payload.person && resp.data.payload.person.email
                        ?resp.data.payload.person.email
                        :"no email received" });
            let generalData=resp.data.payload;
            //sessionStorage.setItem('credentials', JSON.stringify(credentials.auth));
            if(generalData){
                dispatch(login(true, null));
                dispatch(actionCreators.timedOut(false));
                dispatch(actionCreators.errorLogin({message:null,details:null}));
              //  sessionStorage.setItem('user', JSON.stringify(generalData));
                dispatch(setUser(generalData));
                dispatch(setPatientInformation(generalData.patient && generalData.patient.id));
                dispatch(setHospitalData(
                    {id:generalData.hospitalId}
                ));
                if(getHospitalAndData){
                    dispatch(actionCreators.getHospitalData(generalData.hospitalId));
                    dispatch(actionCreators.getStaticData());
                }
            }
            if(resp.data.message && resp.data.message.length>0){
                dispatch(actionCreators.onGeneralModalToggle('modalServerMsg',true,false,false,'GENERAL_TOGGLE_MODAL',resp.data.message));
            }else{
                dispatch(actionCreators.onGeneralModalToggle('modalServerMsg',false,false,false,'GENERAL_TOGGLE_MODAL',false));
            }
            dispatch(actionCreators.setGeneralSaveMsg('',false));
                dispatch(actionCreators.logginLoader(false));
        }).catch(error=>{
            dispatch(actionCreators.logginLoader(false));
            if(error && error.response && error.response.data && error.response.data.error){
                if(showError) {
                    dispatch(actionCreators.setGeneralSaveMsg(error.response.data.error, true));
                    dispatch(errorLogin(error.response.data.error));
                }
                dispatch(actionCreators.login(false, null));
                dispatch(actionCreators.timedOut(false));
            }else{
                if(showError){
                    dispatch(actionCreators.setGeneralSaveMsg('app.serverNotConnected',true,false,true));
                }

            }

        });
    };
};

export const getHospitalDataInfo =(hid)=>{
    return dispatch=> {
        dispatch(actionCreators.hospitalLoading(true));
        axios.get(`${backendURL}/hospital/${hid}/info`,backendConfig)
            .then( resp  => {
                let generalData=resp.data.payload;
                dispatch(setHospitalData(
                    {...generalData, machines:generalData.machines.filter(machine=>!machine.archived)}
                ));
                dispatch(actionCreators.getSettings(generalData.hospital.id));
                dispatch(actionCreators.hospitalLoading(false));
            }).catch(error=>{
            dispatch(actionCreators.hospitalLoading(false));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const getHospitalData =(hospitalId)=>{
    return dispatch=> {
        dispatch(actionCreators.hospitalLoading(true));
        axios.get(`${backendURL}/hospital/${hospitalId}`,backendConfig)
            .then( resp  => {
                Sentry.setTag("uuid", resp.data.payload.uuid
                    ?resp.data.payload.uuid
                    :"no uuid received");
                let generalData=resp.data.payload;
                dispatch(setHospitalData(
                    {...generalData, machines:generalData.machines.filter(machine=>!machine.archived)}
                ));
                dispatch(pageMetadata({machines:generalData.machines}));
                dispatch(actionCreators.getSettings(hospitalId));
                dispatch(actionCreators.hospitalLoading(false));

            }).catch(error=>{
            dispatch(actionCreators.hospitalLoading(false));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const getStaticData =()=>{
    return dispatch=> {
        axios.get(`${backendURL}/staticdata`,backendConfig)
            .then( resp  => {
                let generalData=resp.data.payload;
                let pageData={
                    ...generalData,
                    language:generalData.languages
                };
                dispatch(pageMetadata(pageData));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const setUser = (data) => {
    return {
        type: actionTypes.RESU_CHANGE,
        userInfo:data
    };
};

export const hospital = (data) => {
    return {
        type: actionTypes.HOSPITAL,
        hospital:data
    };
};

export const setHospitalData = (data) => {
    return {
        type: actionTypes.SET_HOSPITAL_DATA,
        hospital:data
    };
};


export const pageMetadata = (data) => {
    return {
        type: actionTypes.PAGE_METADATA,
        pageData:data
    };
};

export const timedOut =(bool)=>{
    return {
        type: actionTypes.TIMED_OUT,
        bool
    };
};

export const logout =(msg)=>{
    return {
        type: actionTypes.RESU_LOGOUT,
        message: msg
    };
};

export const logoutBackEnd = () => {
    return dispatch=> {
        dispatch(actionCreators.logginLoader(false));
        dispatch(actionCreators.login(false, null));
        let credentials = backendConfig;
        credentials.auth = {};
        axios.post(`${backendURL}/logout`,{},credentials)
            .then( resp  => {
                Sentry.setUser({ email: null });
                dispatch(actionCreators.getStaticData());
            }).catch(error=>{
        });
    };
};
