import * as actionTypes from '../Actions/actionTypes';
const dotProp = require('dot-prop-immutable');

const initialState = {};


const interfaceReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_MODE:
            return dotProp.set(state, 'mode', action.mode);

         case actionTypes.SET_BLIND_MODE:
            return dotProp.set(state, 'blindMode', action.mode);

        case actionTypes.REDIRECT_HOME:
            return dotProp.set(state, 'redirectToHomePage', {
                redirectToHomePage:action.redirectToHomePage,
                msg:action.msg
            });
        case actionTypes.SAVED:
            return dotProp.set(state, 'saved', action.saved);

        case actionTypes.OPEN_SIDEBAR:
            return dotProp.set(state, 'sideBarOpen', action.sideBarOpen);

        case actionTypes.RESU_PATIENT_ID:
            return dotProp.set(state, 'userPatientId', action.userPatientId);

        case actionTypes.RESET_FIELD:
            return dotProp.set(state, 'resetField', action.resetField);

        case actionTypes.TOGGLE_SIDEBAR:
            return dotProp.set(state, 'sideBarOpen', action.sideBarOpen);

        case actionTypes.SHOW_SAVE_BTN:
            return dotProp.set(state, 'showSaveBtn', action.showSaveBtn);

        case actionTypes.IS_LOADING:
            return dotProp.set(state, 'loading', action.loading);

        case actionTypes.HOSPITAL_LOADING:
            return dotProp.set(state, 'hospitalLoading', action.hospitalLoading);

        case actionTypes.DATE_FORMAT:
            return dotProp.set(state, 'dateFormat', action.dateFormat);

        case actionTypes.TOGGLE_GENERAL_UI:
            return dotProp.set(state, action.path, action.toggle);

        case actionTypes.HOSPITAL_PROFILE_LOADING:
            return dotProp.set(state, 'hospitalProfileLoading', action.hospitalProfileLoading);

        case actionTypes.NEW_PATIENT:
            return dotProp.set(state, action.e.name, action.e.value);

        case actionTypes.DIRTY_TRANSLATION:
            return dotProp.merge(state, action.e.name, [action.e.value]);

        case actionTypes.RESET_DIRTY_TRANSLATION:
            return dotProp.set(state, action.e.name, action.e.value);

        case actionTypes.SET_ALL_FILE:
            return dotProp.set(state, action.e.name, action.e.value);

        case actionTypes.IS_GENERAL_LOADING:
            return dotProp.set(state, 'generalLoading', action.isLoading);

        default:
            return state;
    }
};

export default interfaceReducer;