import * as actionTypes from '../Actions/actionTypes';

const dotProp = require('dot-prop-immutable');

const initialState = {};


const modalReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.GENERAL_TOGGLE_MODAL:
            return dotProp.set(state,action.name,{
                selected:action.selected,
                open:action.open,
                index:action.index,
                msg:action.msg,
                name:action.nameId
            });

        default:
            return state;
    }
};

export default modalReducer;