import {applyMiddleware, compose, createStore,combineReducers} from "redux";
import loginReducer from "../Reducers/loginReducer";
import interfaceReducer from "../Reducers/interfaceReducer";
import dashboardReducer from "../Reducers/dashboardReducer";
import thunk from "redux-thunk";
import contextPatientReducer from "../Reducers/contextPatientReducer";
import errorReducer from "../Reducers/errorReducer";
import modalReducer from "../Reducers/modalReducer";
import contextHospitalReducer from "../Reducers/contextHospitalReducer";
import contextUserReducer from "../Reducers/contextUserReducer";
import extendedSearchReducer from "../Reducers/extendedSearchReducer";

const logger = store => {
    return next => {
        return action => {
            //console.log('[Middleware] Dispatching', action);
            const result = next(action);
            //console.log('[Middleware] next state', store.getState());
            return result;
        };
    };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
    // main: reducer,
    login: loginReducer,
    ui:interfaceReducer,
    dashboard:dashboardReducer,
    contextPatient:contextPatientReducer,
    error:errorReducer,
    modal:modalReducer,
    contextHospital:contextHospitalReducer,
    contextUser:contextUserReducer,
    extendedSearch:extendedSearchReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'RESU_LOGOUT') {
        //sessionStorage.removeItem('user');
        //sessionStorage.removeItem('credentials');
        state = undefined;
    }
    return appReducer(state, action);
};



const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));

export default store;

