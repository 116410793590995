import React from 'react';
import {Dropdown} from "react-bootstrap";
import CustomOverlay from "../CustomOverlay/CustomOverlay";

const CustomDropdown=(props)=>{

    return <Dropdown>
        {
            props.overlay
                ?<CustomOverlay
                    placement={props.overlay.palcement}
                    tooltipId={props.overlay.tooltipId}
                    tooltipText={props.overlay.tooltipText}
                >
                    <Dropdown.Toggle
                        as={props.as}
                        id={props.id}
                        drop={props.drop}
                        align={props.align}
                        className={props.className}
                    >
                        {props.title}
                    </Dropdown.Toggle>
                </CustomOverlay>
                :<Dropdown.Toggle
                    as={props.as}
                    id={props.id}
                    drop={props.drop}
                    align={props.align}
                    className={props.className}
                >
                    {props.title}
                </Dropdown.Toggle>
        }

        <Dropdown.Menu>
            {props.children}
        </Dropdown.Menu>
    </Dropdown>;
};

export default CustomDropdown;