import * as actionTypes from './actionTypes';
import * as actionCreators from './index';
import axios from 'axios';
import {backendURL, backendConfig} from "../utility";

export const setKpiTempDateRange =(dateFrom,dateTo,relativeTimeSelected,relativeTimeNameSelected)=>{
    return {
        type:actionTypes.SET_KPI_TEMP_DATE_RANGE,
        dateFrom:dateFrom,
        dateTo:dateTo,
        relativeTimeSelected:relativeTimeSelected,
        relativeTimeNameSelected:relativeTimeNameSelected,
    };
};

export const kpiLoading =(data)=>{
    return {
        type:actionTypes.KPI_LOADING,
        kpiLoading: data,
    };
};


export const getKpi =(kpiId,date)=>{
    return dispatch=> {
        dispatch(kpiLoading(true));
        axios.get(`${backendURL}/kpi/${kpiId}${date?(date):""}`,backendConfig )
            .then( resp  => {
                if(resp.data && resp.data.payload){
                    dispatch(onUpdateKpi(resp.data.payload));
                    dispatch(kpiLoading(false));
                }else{
                    //console.log('error');
                    dispatch(kpiLoading(false));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const onUpdateKpi =(data)=>{
    return {
        type:actionTypes.UPDATE_KPI,
        kpi: data,
    };
};

export const selectedKpi =(data)=>{
    return {
        type:actionTypes.SELECTED_KPI,
        selectedKpi: data,
    };
};

