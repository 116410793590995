import * as actionTypes from './actionTypes';
import * as actionCreators from './index';

export const onMonitoringDelaysToggle =(selected)=>{
    return dispatch=>{
        dispatch(actionCreators.resetSaveMsg());
        dispatch(actionCreators.setGeneralSaveMsg("",false));
        dispatch(actionCreators.showSaveBtn(true));
        dispatch(actionCreators.madeAChangeHospital(true));
        return dispatch(monitoringDelaysToggle(selected));
    };
};

export const monitoringDelaysToggle =(selected)=>{
    return {
        type:actionTypes.TOGGLE_MONITORING_DELAYS_MODAL,
        monitoringDelaysToggle:{
            selected:selected
        }
    };
};

export const addMonitoringDelays =(elem)=>{
    return dispatch=>{
        dispatch(actionCreators.resetSaveMsg());
        dispatch(actionCreators.setGeneralSaveMsg("",false));
        dispatch(actionCreators.showSaveBtn(true));
        return dispatch(onAddMonitoringDelays(elem));
    };
};

export const onAddMonitoringDelays =(elem)=>{
    return {
        type:actionTypes.ADD_MONITORING_DELAYS,
        elem:elem
    };
};

export const updateMonitoringDelays =(selected,elem)=>{
    return dispatch=>{
        dispatch(actionCreators.resetSaveMsg());
        dispatch(actionCreators.setGeneralSaveMsg("",false));
        dispatch(actionCreators.showSaveBtn(true));
        return dispatch(onUpdateMonitoringDelays(selected,elem));
    };
};

export const onUpdateMonitoringDelays =(selected,elem)=>{
    return {
        type:actionTypes.UPDATE_MONITORING_DELAYS,
        index:selected,
        elem:elem
    };
};


export const deleteMonitoringDelays =(selected)=>{
    return dispatch=>{
        dispatch(actionCreators.resetSaveMsg());
        dispatch(actionCreators.setGeneralSaveMsg("",false));
        dispatch(actionCreators.showSaveBtn(true));
        return dispatch(onDeleteMonitoringDelays(selected));
    };
};

export const onDeleteMonitoringDelays =(selected)=>{
    return {
        type:actionTypes.DELETE_MONITORING_DELAYS,
        index:selected
    };
};