import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actionCreators from './index';
import {backendURL, backendConfig} from "../utility";

export const createLetter =(letterId,name,patientId,letterTemplateId,monitoringId,timezone)=>{
    return dispatch=> {
        axios.post(`${backendURL}/letter/create/${monitoringId}`, {
            letterTemplateId,
            patientId,
            timezone:timezone
        },backendConfig )
            .then( resp  => {
                if(resp.data){
                    dispatch(onCreateLetter(resp.data,patientId));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const onCreateLetter =(letter,patientId)=>{
    return {
        type:actionTypes.CREATE_LETTER,
        letter: {
            ...letter
        }
    };
};

export const onToggleModalLetter =(id,name,open)=>{
    return {
        type:actionTypes.TOGGLE_LETTER_MODAL,
        open:open,
        id:id,
        name:name,
    };
};

export const onChangeLetter =(content)=>{
    return {
        type:actionTypes.ON_CHANGE_LETTER,
        letterText:content
    };
};

export const onDeleteLetter =(letterGroupIndex,letterIndex)=>{
    return dispatch=>{
        dispatch(onCreateLetter('',''));
        dispatch(onToggleModalLetter('','',false));
        dispatch(getLetterOnLoad('','',''));
        dispatch(deleteLocalLetter(letterGroupIndex,letterIndex));
    };
};

export const deleteLocalLetter=(letterGroupIndex,letterIndex)=>{
    return {
        type:actionTypes.ON_DELETE_LETTER,
        letterGroupIndex,
        letterIndex
    };
};

export const sendAllLetter =(letters,patientId)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/letter/patient/${patientId}/letters`,letters,backendConfig )
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                dispatch(actionCreators.madeAchange(false));
                dispatch(actionCreators.userMadeAChange(false));
                dispatch(actionCreators.madeAChangeHospital(false));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.userUpdated",false));
                dispatch(onUpdateAllLetters(resp.data));
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const onDeleteLetterPost =(letterId,patientId)=>{
    return dispatch=> {
        axios.delete(`${backendURL}/letter/${letterId}`,backendConfig )
            .then( resp  => {
                if(resp.data.response){
                    dispatch(onCreateLetter('',''));
                    dispatch(getAllLetter(patientId));
                    dispatch(onToggleModalLetter('','',false));
                    dispatch(getLetterOnLoad('','',''));
                }else{
                    dispatch(getAllLetter(patientId));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const onUpdateLetter =(objectLetter,patientId)=>{
    return dispatch=> {
        axios.post(`${backendURL}/letter/${objectLetter.id}/patient/${patientId}`,
            objectLetter,
            backendConfig  )
            .then( resp  => {
                if(resp.data){
                    dispatch(onCreateLetter('',''));
                    dispatch(onToggleModalLetter('','',false));
                    dispatch(getAllLetter(patientId));
                    dispatch(getLetterOnLoad('','',''));
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const onSentLetter =(letterId)=>{
    return dispatch=> {
        window.location.href = `${backendURL}/letter/approve/${letterId}`;
    };
};

export const onDownloadLetter =(letterId)=>{
    return dispatch=> {
        window.location.href = `${backendURL}/letter/download/${letterId}`;
    };
};


export const onEditLetter =(letterId,groupId,resp)=>{
    return {
        type:actionTypes.EDIT_LETTER,
        letterId:letterId,
        groupId:groupId,
        resp
    };
};

export const onEditAndGetLetter =(letterId,groupId)=>{
    return dispatch=> {
        axios.get(`${backendURL}/letter/${letterId}`,backendConfig)
            .then( resp  => {
                dispatch(onEditLetter(letterId,groupId,resp.data));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};


export const getLetterOnLoad =(letterId,name,letterGroupId)=>{
    return {
        type:actionTypes.OPEN_LETTER,
        letterId:letterId,
        name:name,
        letterGroupId:letterGroupId
    };
};

export const onApproveLetter =(letter,patientId)=>{
    return dispatch=> {
        axios.post(`${backendURL}/letter/approve/${letter.id}`,
            letter,
            backendConfig  )
            .then( resp  => {
                dispatch(getAllLetter(patientId));
                dispatch(onDownloadLetter(letter.id));
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const getAllLetterLoading =(loading)=>{
    return {
        type:actionTypes.ALL_LETTER_LOADING,
        allLetterLoading:loading
    };
};


export const getAllLetter =(patientId,openModalLetter)=>{
    return dispatch=> {
        dispatch(getAllLetterLoading(true));
        axios.get(`${backendURL}/letter/patient/${patientId}/letters`,backendConfig )
            .then( resp  => {
                if(resp.data){
                    dispatch(onUpdateAllLetters(resp.data));
                    dispatch(getAllLetterLoading(false));
                    openModalLetter();
                }
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(getAllLetterLoading(false));
        });
    };
};

export const onUpdateAllLetters =(letters)=>{
    return {
        type:actionTypes.UPDATE_ALL_LETTERS,
        letters:letters
    };
};

export const toggleLetter =(id,open,path)=>{
    return {
        type:actionTypes.TOGGLE_LETTER,
        value:{
            id:id,
            open:open
        },
        path:path,
    };
};

export const resetToggleLetter =()=>{
    return {
        type:actionTypes.RESET_TOGGLE_LETTER
    };
};