import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actionCreators from './index';
import {backendURL, backendConfig} from "../utility";


export const hospitalProfileLoading =(hospitalProfileLoading)=>{
    return {
        type:actionTypes.HOSPITAL_PROFILE_LOADING,
        hospitalProfileLoading,
    };
};

export const setHospitalProfile =(setHospitalProfile)=>{
    return {
        type:actionTypes.SET_HOSPITAL_PROFILE,
        setHospitalProfile,
    };
};


export const getHospitalProfile =(hId)=>{
    return dispatch=> {
        if(!hId) return "error";
        dispatch(hospitalProfileLoading(true));
        axios.get(`${backendURL}/hospital/${hId}/profile`,backendConfig )
            .then( resp  => {
                if(resp.data && resp.data.payload){
                    dispatch(setHospitalProfile(resp.data.payload.items));
                }
                dispatch(hospitalProfileLoading(false));
            }).catch(error=>{
            dispatch(hospitalProfileLoading(false));
            if(error && error.response){
                dispatch(actionCreators.setGeneralSaveMsg(error.response.data.error.message ,true,error.response.data.error.details));
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

export const postHospitalProfile =(hId,data)=>{
    return dispatch=> {
        if(!hId) return "error";
        dispatch(hospitalProfileLoading(true));
        axios.post(`${backendURL}/hospital/${hId}/profile`,data,backendConfig )
            .then( resp  => {
                if(resp.data && resp.data.payload){
                    dispatch(setHospitalProfile(resp.data.payload.items));
                    dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","editionMode"));
                    dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.profileUpdated",false));
                }
                dispatch(hospitalProfileLoading(false));
            }).catch(error=>{
            dispatch(hospitalProfileLoading(false));
            if(error && error.response){
                dispatch(actionCreators.setGeneralSaveMsg(error.response.data.error.message ,true,error.response.data.error.details));
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
        });
    };
};

