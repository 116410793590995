import React from 'react';
import {Dropdown} from 'react-bootstrap';
import {Trans, useTranslation} from "react-i18next";
import {VscTextSize} from "react-icons/vsc";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import {useDispatch, useSelector} from "react-redux";
import * as actionCreators from "../../../../Store/Actions";
import {useCheck} from "../../../../Hooks/onDefaultValueCheck/onDefaultValueCheck";
import process from 'process';
import UpdateLink from "../UpdateLink/UpdateLink";

const AppFooter=()=>{
    const { t } = useTranslation();
    const [check]=useCheck();
    const dispatch = useDispatch();
    const year=new Date().getFullYear();
    const brand =  process.env.REACT_APP_BRAND
        ?process.env.REACT_APP_BRAND
        :"BoneMonitor";
    const UiBlindMode = useSelector(state=> check(state,'login.userInfo.blindMode','NORMAL'));
    const uid = useSelector(state=>check(state,'login.userInfo.id',false));


    const showPol=(v,transPath)=>{
        if(process.env.REACT_APP_BRAND && process.env[v] ){
            return  <span><a href={process.env[v]}
                       target="_blank" rel="noreferrer noopener"
                       className="btn-link white text-uppercase" title="Privacy Policy">
                {t(transPath)}
            </a></span>;
        }else if(process.env.REACT_APP_BRAND && !process.env[v]){
            return  null;
        }else{
            return <span><a href="https://bonemonitor.com/policy"
                      target="_blank" rel="noreferrer noopener"
                      className="btn-link white text-uppercase" title="Privacy Policy">
                {t(transPath)}
            </a></span>;
        }
    };

    const changeBlindMode=(mode)=>{
        //localStorage.setItem('blindMode', JSON.stringify( mode ));
        let obj={
            target:{
                name:"userInfo.blindMode",
                value:mode.toUpperCase()
            }
        };
        dispatch(actionCreators.setBlindMode(mode));
        dispatch(actionCreators.onGeneralChange(obj,'HOSPITAL_DETAILS_CHANGE'));
        dispatch(actionCreators.sendMode({
                blindMode: mode.toUpperCase()
            },uid
        ));
    };

    const checkIfSelected=(mode)=>{
        if(mode==='SMALL'){
            if(mode===UiBlindMode){
                return 'selectedBlind fontSmall';
            }else{
                return 'fontSmall';
            }
        }else if(mode==='NORMAL'){
            if(mode===UiBlindMode){
                return 'selectedBlind fontNormal';
            }else{
                return 'fontNormal';
            }
        }else if(mode==='LARGE'){
            if(mode===UiBlindMode){
                return 'selectedBlind fontLarge';
            }else{
                return 'fontLarge';
            }
        }else if(mode==='HUGE'){
            if(mode===UiBlindMode){
                return 'selectedBlind fontHuge';
            }else{
                return 'fontHuge';
            }
        }
    };

    const ver = t('app.footer.version', {version: process.env.REACT_APP_VERSION});
    return (
        <div className='appFooterContainer backgroundfooter white text-center p-1'>
                        {showPol('REACT_APP_PRIVACY_POLICY_PATH','app.footer.privacyPolicy')}
                        {showPol('REACT_APP_COOKIE_POLICY_PATH','app.footer.cookiesPolicy')}
            {/*<CustomOverlay
                placement="top"
                tooltipId="button-tooltip-infoFooter"
                tooltipText={t('app.documentation')}
            >
                <a href="https://docs.bonemonitor.com/" target='_blank' rel="noopener noreffer noreferrer">
                    <IoIosHelpCircle color='white' className='contextIcoSmall newCursor'/>
                </a>
            </CustomOverlay>*/}
                    <span >
                                <CustomDropdown
                                    as="div"
                                    id="dropdown-blind"
                                    drop="up"
                                    align="center"
                                    className="blindIco"
                                    overlay={{placement:"top",
                                        tooltipId:"button-tooltip-fontSize",
                                        tooltipText:t('app.fontSize')}}
                                    title={<VscTextSize size={20}/>}
                                >
                                    <Dropdown.Item className={checkIfSelected("SMALL")} onClick={()=>{
                                        changeBlindMode("SMALL");
                                    }}>Small</Dropdown.Item>
                                    <Dropdown.Item className={checkIfSelected("NORMAL")}
                                                   onClick={()=>{
                                                       changeBlindMode("NORMAL");
                                                   }}>Normal</Dropdown.Item>
                                    <Dropdown.Item className={checkIfSelected("LARGE")}
                                                   onClick={()=>{
                                                       changeBlindMode("LARGE");
                                                   }}>Large</Dropdown.Item>
                                    <Dropdown.Item className={checkIfSelected("HUGE")}
                                                   onClick={()=>{
                                                       changeBlindMode("HUGE");
                                                   }}>Huge</Dropdown.Item>
                                </CustomDropdown>
                            </span>
                    <span className='footerVersion'
                      title={ver}>
                        {(ver?ver:'').split('-')[0]} <UpdateLink/>
                    </span>
                        <span className='footerCopyright'>
                            { process.env.REACT_APP_BRAND
                                ?<span>{new Date().getFullYear()} Copyright <a className='customLink'
                                                                               href="https://bonemonitor.com"
                                                                               target='_blank'
                                                                               rel='noreferrer noopener'>BoneMonitor</a>
                                    - Endorsed by <a className='customLink'
                                                     href="https://osteoporosis.foundation"
                                                     target='_blank' rel='noreferrer noopener'>IOF</a></span>
                                    : <Trans i18nKey="app.footer.copyright" >
                                        © Copyright {{year}} - {{brand}}
                                    </Trans>
                            }

                        </span>
        </div>
    );
};

export default AppFooter;