import * as actionTypes from '../Actions/actionTypes';
import {ChangePathName} from "../../Hooks/ChangePathName/ChangePathName";
const dotProp = require('dot-prop-immutable');

/*const getSessionFromCookie = () => {
    if (sessionStorage && sessionStorage.user)
    {
        try {
            return JSON.parse(sessionStorage.user);
        } catch (e) {
            return false;
        }
    }
    else {
        return false;
    }
};*/


const initialState = {
    logged: /*getSessionFromCookie()!==*/false
};

const changeNullValue=(name,state,isArr)=>{
    let updatedName=ChangePathName(name);
    let arr=updatedName.split('.');
    let newName=arr[0];
    let newState=state;
    for (var i=1;i<arr.length;i++){
        newName=newName+'.'+arr[i];
        if(dotProp.get(state, newName)===undefined || dotProp.get(state, newName)===null){
            if(Number(arr[i+1])>=0){
                newState=dotProp.set(newState, newName, [{}]);
            }else{
                if(isArr && i===arr.length-1 ){
                    newState=dotProp.set(newState, newName, []);
                }else{
                    newState=dotProp.set(newState, newName, {});
                }

            }
        }
    }
    return newState;
};

const login = ( state = initialState, action ) => {

    switch ( action.type ) {
        //set general array
        case actionTypes.SET_PROFILE_ARRAY:
            return dotProp.set(state, action.path, action.array);

        case actionTypes.SET_SELECTED_FILE:
            return dotProp.set(state, action.name, action.value);
        //monitoring delays
        case actionTypes.DELETE_MONITORING_DELAYS:
            return dotProp.delete(state,`hospital.hospitalMonitoringDelays.${action.index}`);

        case actionTypes.UPDATE_MONITORING_DELAYS:
            return dotProp.set(state,`hospital.hospitalMonitoringDelays.${action.index}`,action.elem);

        case actionTypes.ADD_MONITORING_DELAYS:
            return dotProp.merge(state,'hospital.hospitalMonitoringDelays',action.elem);

        case actionTypes.ON_CHANGE_LANG:
            return dotProp.set(state, action.e.name.name, action.e.value);

        case actionTypes.HOSPITAL_DETAILS_CHANGE:
            return dotProp.set(state, action.e.name, action.e.value);

        case actionTypes.DELETE_IN_LOGIN:
            return dotProp.delete(state, `${action.name}.${action.elemIndex}`);

        case actionTypes.UPDATE_IN_LOGIN:
            return dotProp.set(state,`${action.name}.${action.elemIndex}`,action.elem);

        case actionTypes.ADD_IN_LOGIN:
            return dotProp.merge( state, `${action.name}`, action.elem);

        //drugs
        case actionTypes.SET_MASTER_DRUGS:
            return dotProp.set(state,`pageData.drugs`,action.drugs);

        case actionTypes.SET_ANTIOSTEO_MEDICATION:
            return dotProp.set(state,`pageData.drugs`,action.drugs);

        case actionTypes.CHANGE_DRUGS:
            return dotProp.set(state,action.name,action.bool);

        case actionTypes.SET_ANTIOSTEO_MEDICATION_SELECTED:
            return dotProp.set(state,`hospital.drugHospital`,action.selectedDrugs);

        //machines
        case actionTypes.SET_MACHINES_MASTER:
           return dotProp.set(state,'pageData.machines',action.machines);

        case actionTypes.SET_MACHINES:
            return dotProp.set(state,'hospital.machines',action.machinesFiltered);

            case actionTypes.SET_HOSPITAL_DATA:
            return dotProp.set(state, 'hospital',  action.hospital);
        //PAGE DATA
        case actionTypes.HOSPITAL:
            return dotProp.set(state, 'hospital', {...state.hospital, ...action.hospital});
//drugHospital:action.hospital.drugHospital?action.hospital.drugHospital:state.hospital.drugHospital,
        case actionTypes.PAGE_METADATA:
            return dotProp.merge(state,'pageData', {...action.pageData});

        //LOGIN
        case actionTypes.LOGIN:
            return dotProp.set(state,'logged',action.logged);

        case actionTypes.ERROR_LOGIN:
            return dotProp.set(state,'loginMessageDetails',action.msgDetails);

        case actionTypes.RESU_CHANGE:
            return dotProp.set(state,'userInfo',action.userInfo);

        case actionTypes.TIMED_OUT:
            return dotProp.set(state,'timedOut',action.bool);

        case actionTypes.RESU_LOGOUT:
            return dotProp.set(state,'logged', false);
    //CHANGE PROFILE
        case actionTypes.UPDATE_PROFILE_ITEMS:
            return dotProp.set(changeNullValue(action.name,state,action.isArr),ChangePathName( `${action.name}.${action.elemIndex}`),action.elem);

        case actionTypes.ADD_PROFILE_ITEMS:
            return dotProp.merge(changeNullValue(action.name,state,action.isArr), ChangePathName( action.name), action.elem);

        case actionTypes.SET_HOSPITAL_PROFILE:
            return dotProp.set(state, 'hospital.profile.items', action.setHospitalProfile);

        case actionTypes.LOGGIN_LOADER:
            return dotProp.set(state, 'loading', action.loading);

        default:
            return state;
    }
};

export default login;