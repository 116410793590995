import * as actionTypes from '../Actions/actionTypes';
const dotProp = require('dot-prop-immutable');

const initialState = {};

const dashboardReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        case actionTypes.PATIENT_GRID_LOADING:
            return dotProp.set(state, 'patientGridLoading', action.patientGridLoading);

        case actionTypes.ORDER_BY_PATIENT:
            return dotProp.merge(state, 'dashboard.patientGrid.order', {
                orderBy: action.orderBy,
                direction: action.direction
            });

        case actionTypes.SET_PATIENT_COUNT:
            return dotProp.set(state, 'dashboard.patientCount', action.patientCount);

        case actionTypes.SET_TASK:
            return dotProp.set(state, 'dashboard.taskGrid.tasks', action.tasks);

        case actionTypes.SET_TASKS_COUNT:
            return dotProp.set(state, 'dashboard.taskGrid.tasksCount', action.tasksCount);

        case actionTypes.FILTER_TASKS:
            return dotProp.merge(state, 'dashboard.taskGrid.filters', {
                days:action.days,
                hide:action.hide,
                taskType:action.taskType
            });

        case actionTypes.FILTER_TASKS_PATIENTS:
            return dotProp.merge(state, 'dashboard.taskGridPatient.filters', {
                days:action.days,
                hide:action.hide,
                taskType:action.taskType
            });

        case actionTypes.ORDER_TASKS:
            return dotProp.merge(state, 'dashboard.taskGrid.order', {
                orderBy: action.orderBy,
                direction: action.direction
            });

        case actionTypes.ORDER_TASKS_PATIENT:
            return dotProp.merge(state, 'dashboard.taskGridPatient.order', {
                orderBy: action.orderBy,
                direction: action.direction
            });

        case actionTypes.PATIENT_GRID:
            return dotProp.set(state, 'dashboard.patients',action.patients );
/*
* {...state.dashboard,
                patientGrid: {
                    ...state.dashboard.patientGrid,
                    patients:action.patientSearchResults
                },
                patientSearchResultsCount: action.patientSearchResultsCount}
* */
        case actionTypes.SEARCH_PATIENT_INPUT:
            return dotProp.set(state, 'dashboard.searchPatient',action.searchPatient );

        case actionTypes.SET_CURSOR:
            return dotProp.set(state, 'cursor',0);
        case actionTypes.LOADING_DASHBOARD_TASK_CSV:
            return dotProp.set(state, 'loadingDashboardTaskCsv',action.loadingDashboardTaskCsv);
        case actionTypes.LOADING_DASHBOARD_PATIENT_CSV:
            return dotProp.set(state, 'loadingDashboardPatientCsv',action.loadingDashboardPatientCsv);
        case actionTypes.LOADING_DASHBOARD_TASK_PDF:
            return dotProp.set(state, 'loadingDashboardTaskPdf',action.loadingDashboardTaskPdf);
        case actionTypes.LOADING_DASHBOARD_PATIENT_PDF:
            return dotProp.set(state, 'loadingDashboardPatientPdf',action.loadingDashboardPatientPdf);
        case actionTypes.PATH_DASHBOARD_TASK_CSV:
            return dotProp.set(state, 'pathDashboardTaskCSV',action.pathDashboardTaskCSV);
        case actionTypes.PATH_DASHBOARD_PATIENT_CSV:
            return dotProp.set(state, 'pathDashboardPatientCSV',action.pathDashboardPatientCSV);
        case actionTypes.PATH_DASHBOARD_PATIENT_PDF:
            return dotProp.set(state, 'pathDashboardPatientPDF',action.pathDashboardPatientPDF);
        case actionTypes.PATH_DASHBOARD_TASK_PDF:
            return dotProp.set(state, 'pathDashboardTaskPDF',action.pathDashboardTaskPDF);

        default:
            return state;
    }
};

export default dashboardReducer;