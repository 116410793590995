import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actionCreators from './index';
import {backendURL, backendConfig} from "../utility";

export const dlGDPRpdf =(patientId)=>{
    return dispatch => window.location.href = `${backendURL}/letter/gdpr/${patientId}`;
};


export const madeAchange =(data)=>{
    return {
        type:actionTypes.MADE_A_CHANGE,
        madeAchange:data
    };
};

export const addPatientMode =(mode)=>{
    return dispatch => {
        if(mode){
            dispatch(actionCreators.resetErrorField());
        }
        return dispatch(addPatientModeChange(mode));
    };
};

export const addPatientModeChange =(mode)=>{
    return {
        type: actionTypes.ADD_PATIENT_MODE,
        addPatientMode:mode,
        contextPatient:{}
    };
};

export const resetPatientMode =()=>{
    return {
        type: actionTypes.RESET_PATIENT_MODE,
        addPatientMode:false,
    };
};

export const errorLocation =(error)=>{
    return {
        type: actionTypes.ERROR_FIELD_BE,
        errorLocation:error,
    };
};


export const addPatient =(data,id)=>{
    return dispatch=> {
        dispatch(actionCreators.savePatient(false));
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        axios.post(`${backendURL}/patient/${id?id:''}`,
            data
            ,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.resetErrorField());
                dispatch(actionCreators.madeAchange(false));
                dispatch(actionCreators.userMadeAChange(false));
                dispatch(actionCreators.madeAChangeHospital(false));
                dispatch(actionCreators.showSaveBtnAndResetMsg(false));
                dispatch(actionCreators.saved(true));
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                if(resp.data.payload){
                    dispatch(setPatientError(false,true));
                }
                if(resp.data.payload.administrative.patientInformation.id){
                    dispatch(setAddPatient(resp.data.payload,true));
                    dispatch(setPatientError(false,true,'app.validMsg.updated'));
                    dispatch(actionCreators.resetErrorField());
                    let obj={
                        target:{
                            value:true,
                            name:'newPatient'
                        }
                    };
                    dispatch(actionCreators.onGeneralChange(obj, 'NEW_PATIENT'));
                    //dispatch(getAllLetter(resp.data.payload.administrative.patientInformation.id));
                }
                dispatch(actionCreators.setGeneralSaveMsg("app.validMsg.userUpdated",false));
                dispatch(actionCreators.savePatient(false));
            }).catch(error=>{
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            if(error && error.response){
                if(error.response.data.error && error.response.data.error.field){
                    dispatch(setPatientError(true,false));
                }else{
                    dispatch(setPatientError(false,false));
                }
                dispatch(actionCreators.handleBackendError(error.response,id));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.saved(false));
        });
    };
};

export const setAddPatient =(data,save)=>{
    // add letters and tasks
    return dispatch => {
        dispatch(savePatient(save));
        dispatch(actionCreators.madeAchange(false));
        return dispatch(newAddedPatient(data));
    };
};

export const savePatient =(save)=>{
    // add letters and tasks
    return {
        type: actionTypes.SAVE_PATIENT,
        patientSaved:save
    };
};

export const newAddedPatient =(data)=>{
    // add letters and tasks
    return {
        type: actionTypes.ADD_PATIENT,
        contextPatient: data
    };
};

export const setPatientError =(data,saved)=>{
    return {
        type: actionTypes.ERROR_PATIENT,
        patientSaved:saved,
        patientIdExist:data
    };
};

export const resetPatient =(country)=>{
    return {
        type: actionTypes.RESET_PATIENT,
        country
    };
};


export const getPatientInfo =(id,getLetter)=>{
    return dispatch=> {
        dispatch(actionCreators.toggleGeneral(true,"TOGGLE_GENERAL_UI","showSaveLoader"));
        dispatch(actionCreators.loading(true));
        dispatch(actionCreators.updateCA(0));
        dispatch(actionCreators.showSaveBtnAndResetMsg(false));
        axios.get(`${backendURL}/patient/${id}`,backendConfig)
            .then( resp  => {
                dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
                if(resp.data.payload.administrative.patientInformation.id){
                    dispatch(setGetPatient(resp.data.payload));
                    //dispatch(getAllLetter(id));
                }
                if(getLetter) getLetter();
            }).catch(error=>{
            if(error && error.response){
                dispatch(actionCreators.handleBackendError(error.response));
            }else{
                if(error.status <200 && error.status >299 )dispatch(actionCreators.setGeneralSaveMsg('defaultErrorMSg',true));
            }
            dispatch(actionCreators.toggleGeneral(false,"TOGGLE_GENERAL_UI","showSaveLoader"));
            dispatch(actionCreators.loading(false));
        });
    };
};

export const setGetPatient =(data)=>{
    return dispatch => {
        dispatch(savePatient(false));
        return dispatch(setPatient(data));
    };
};

export const setPatient =(data)=>{
    if(data.letters===null){
        return {
            type: actionTypes.SET_PATIENT,
            administrative:{
                ...data.administrative,
            },
            clinicalContent:{
                ...data.clinicalContent,
            },
            letters:[]
        };
    }else{
        return {
            type: actionTypes.SET_PATIENT,
            administrative:{
                ...data.administrative,
            },
            clinicalContent:{
                ...data.clinicalContent,
            },
            //letters:[...data.letters]
        };
    }

};
